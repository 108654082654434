import { useMemo } from 'react';
import clsx from 'clsx';
import { PropsType, SignatoryType } from '../../types';
import { StepLayout } from './step-layout';
import { Signatory } from '../signatory';
import { FieldGroup } from '../field-group';
import { Checkbox } from '@/isomorphic/components/forms/components/checkbox';
import { validators } from '@/hooks/use-validation';

type Props = PropsType & {
    selectedSignatoryGroup: number | null;
    setSelectedSignatoryGroup: (value: number) => void;
};

export const StepFour = ({
    getLabel,
    updateFields,
    getValuesFromStorage,
    selectedSignatoryGroup,
    corporateRegistryDetails,
    setSelectedSignatoryGroup,
    fields: { signatoryCombinations, keyRoles },
    ...rest
}: Props) => {
    const storageValue = getValuesFromStorage();

    const updateSignatory = (field: Partial<SignatoryType>, index: number) => {
        const updatedSignatories = [...signatoryCombinations];
        const updatedSignatory = updatedSignatories[index];
        updatedSignatories.splice(index, 1, {
            ...updatedSignatory,
            ...field,
        });

        updateFields({ signatoryCombinations: updatedSignatories });
    };

    const onCheckboxChange = (index: number) => {
        if (selectedSignatoryGroup === index) {
            return;
        }

        const signatoryCombinations =
            corporateRegistryDetails.combinations?.[index]?.signatories?.map(({ governmentId, name, role }) => {
                const storedSignatory = storageValue?.signatoryCombinations?.find(signatory => signatory.governmentId?.id === governmentId.id);
                const keyRole = keyRoles.find(keyRole => keyRole.governmentId.id === governmentId.id);

                return {
                    role,
                    name,
                    governmentId,
                    phoneNumber: keyRole ? keyRole.phoneNumber : storedSignatory?.phoneNumber ?? '',
                    email: keyRole ? keyRole.email : storedSignatory?.email ?? '',
                };
            }) ?? null;

        if (!signatoryCombinations) {
            return;
        }

        setSelectedSignatoryGroup(index);
        updateFields({ signatoryCombinations });
    };

    const isSubmitDisabled = useMemo(() => {
        if (rest.step === 4) {
            return selectedSignatoryGroup === null;
        }

        return !signatoryCombinations.every(signatory => validators.email(signatory.email) && validators.swedishPhone(signatory.phoneNumber));
    }, [signatoryCombinations, rest.step, selectedSignatoryGroup]);

    return (
        <StepLayout title={getLabel('signees')} isSubmitDisabled={isSubmitDisabled} getLabel={getLabel} {...rest}>
            <div
                className="r-prose -r-mt-4 r-mb-4"
                dangerouslySetInnerHTML={{ __html: rest.step === 4 ? getLabel('whoWillSignSelect') : getLabel('whoWillSign') }}
            />
            <FieldGroup className="r-space-y-4">
                {corporateRegistryDetails.combinations.map((combination, index) => (
                    <div
                        key={index}
                        className={clsx(
                            'r-rounded-lg r-p-4 r-text-sm',
                            selectedSignatoryGroup === index ? 'r-border-2 r-border-primary' : 'r-border r-border-gray-300',
                            {
                                'r-hidden': rest.step === 4.5 && selectedSignatoryGroup !== index,
                            }
                        )}
                    >
                        <Checkbox
                            value={index}
                            type="radio"
                            name={`signatory--${index}`}
                            label={combination.signatories.map(s => `${s.name.first} ${s.name.last}`).join(' | ')}
                            checked={selectedSignatoryGroup === index}
                            onChange={() => onCheckboxChange(index)}
                        />
                    </div>
                ))}
            </FieldGroup>
            {rest.step === 4.5
                ? signatoryCombinations.map((signatory, index) => (
                      <Signatory key={index} signatory={signatory} getLabel={getLabel} onUpdate={field => updateSignatory(field, index)} />
                  ))
                : null}
        </StepLayout>
    );
};
