import { useEffect, useState } from 'react';
import MenuItems from './menu-items';
import { useNavMenus } from '@/hooks/use-nav-menus';
import { Header } from './header';
import { TabMenu } from './tab-menu';
import { Search } from './search';
import { Close } from './close';
import { SearchResults } from './search-results';
import { useGlobalQueries } from '@/hooks/use-global-queries';
import { useLocalization } from '@/hooks/use-localization';
import { useMobileMenus } from '@/hooks/use-mobile-menus';
import { useSearch } from '@/hooks/use-search';
import { useHumanySearch } from '@/hooks/use-humany-search';

export const MobileMenu = () => {
    const { getMarketType } = useGlobalQueries();
    const { localize } = useLocalization();
    const { mainMenu } = useNavMenus();

    const getInitialActiveTab = () => {
        if (typeof window === 'undefined') {
            return localize('private');
        }

        const storedTab = window.localStorage.getItem('activeTab');
        return storedTab === localize('corporate') ? localize('corporate') : localize('private');
    };

    const [showMenu, setShowMenu] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [page, setPage] = useState(1);
    const [activeTab, setActiveTab] = useState(getInitialActiveTab);

    const { privateMenu, corporateMenu } = useMobileMenus({
        privateEnabled: activeTab === localize('private') && !!getMarketType() && showMenu,
        corporateEnabled: activeTab === localize('corporate') && !!getMarketType() && showMenu,
    });
    const { searchResults } = useSearch({
        searchValue,
        page,
    });

    const { humanySearchResults } = useHumanySearch(searchValue);
    const menuItems = getMarketType() !== 'cluster' ? privateMenu.data ?? [] : mainMenu?.data || [];

    useEffect(() => {
        window?.localStorage?.setItem('activeTab', activeTab);
    }, [activeTab]);

    useEffect(() => {
        // Prevent scrolling of document when menu is open
        if (showMenu) {
            document.body.style.overflow = 'hidden';
            return;
        }

        document.body.style.overflow = 'visible';

        return () => {
            if (!showMenu) {
                document.body.style.overflow = 'visible';
            }
        };
    }, [showMenu]);

    useEffect(() => {
        if (searchValue) {
            setPage(1);
        }
    }, [searchValue]);

    return (
        <>
            {!showMenu ? (
                <Header setShowMenu={setShowMenu} />
            ) : (
                <div className="scrollbar-hide r-absolute r-h-[100vh] r-w-full r-overflow-y-scroll r-bg-white md:r-hidden">
                    <div className="r-flex r-flex-col r-p-4 r-pb-24">
                        <Close setShowMenu={setShowMenu} />
                        <Search setSearchValue={setSearchValue} searchValue={searchValue} />
                        {searchValue.length < 3 ? (
                            <>
                                {getMarketType() !== 'cluster' && <TabMenu activeTab={activeTab} setActiveTab={setActiveTab} />}
                                <div className="r-mt-10">
                                    <MenuItems
                                        setShowMenu={setShowMenu}
                                        items={activeTab === localize('private') ? menuItems : corporateMenu.data ?? []}
                                    />
                                </div>
                            </>
                        ) : (
                            <SearchResults
                                totalHumanyMatches={humanySearchResults?.data?.TotalMatches || 0}
                                totalSearchHits={searchResults.data?.nbHits || 0}
                                setShowMenu={setShowMenu}
                                searchValue={searchValue}
                                searchHits={searchResults.data?.hits || []}
                                humanyMatches={humanySearchResults?.data?.Matches || []}
                                page={page}
                                setPage={setPage}
                            />
                        )}
                    </div>
                </div>
            )}
        </>
    );
};
