import { useEffect } from 'react';
import clsx from 'clsx';
import { Size } from '@/utils/typography';
import { Button } from '@/isomorphic/components/typography/button/button';
import { useMyEconomyDrawer } from '@/hooks/use-my-economy-drawer';
import { useGtm } from '@/hooks/use-gtm';
import { addScriptTag, deactivateWidget } from '../../page/components/humany-chatbot';
import { sendAsGoal } from '@/hooks/use-ab-tests';

export type Variant = 'standard' | 'arrow' | 'bankidwhite' | 'bankidnorwaywhite' | 'bankidnorwaypurple';

export type ButtonProps = {
    url: string;
    text: TrustedHTML;
    type: 'primary' | 'secondary' | 'secondaryBorder' | 'tertiary';
    size: Size;
    isMyEconomyButton?: boolean;
    isHumanyChatBotButton?: boolean;
    humanyChatBotName?: string;
    myEconomyCourse?: string;
    margin: 'none' | 'top' | 'bottom' | 'topAndBottom';
    variant: Variant;
    fullWidth: boolean;
    gtmLabel: string;
    abFirstGoal?: {
        useAsGoal: boolean;
        abTestUuid: string;
    };
    gtmCategory: {
        title?: string;
        id?: string;
        __reference: string;
        error?: number | string;
    };
};

export const ResursButton = ({
    url = '',
    text = '',
    isMyEconomyButton = false,
    isHumanyChatBotButton = false,
    myEconomyCourse = '',
    margin = 'none',
    humanyChatBotName = 'resurs-bot',
    gtmLabel = '',
    gtmCategory,
    abFirstGoal,
    ...props
}: ButtonProps) => {
    const { setDataLayer } = useGtm();
    const { setMyEconomyDrawer } = useMyEconomyDrawer();
    const classNames = clsx({
        'r-mb-6': margin === 'bottom',
        'r-mt-6': margin === 'top',
        'r-my-6': margin === 'topAndBottom',
    });

    useEffect(() => {
        if (isHumanyChatBotButton) {
            addScriptTag({ widgetName: humanyChatBotName, activate: false });
        }

        return () => {
            if (isHumanyChatBotButton && humanyChatBotName) {
                deactivateWidget(humanyChatBotName);
            }
        };
    }, [isHumanyChatBotButton]);

    const onClick = () => {
        if (gtmLabel && gtmCategory) {
            setDataLayer({
                action: 'click',
                category: gtmCategory?.title ?? 'click',
                label: gtmLabel ?? '',
                value: '',
            });
        }

        if (abFirstGoal?.useAsGoal) {
            sendAsGoal(abFirstGoal.abTestUuid);
        }

        if (isMyEconomyButton) {
            setMyEconomyDrawer({ isOpen: true, startCourse: myEconomyCourse });
        }

        if (isHumanyChatBotButton && humanyChatBotName) {
            openChatBot();
        }
    };

    const openChatBot = async () => {
        try {
            const widget = window.humany?.widgets?.find(humanyChatBotName);
            if (!widget) {
                return;
            }

            if (widget.state !== 'activated') {
                await widget.activate();
            }

            widget.invoke('start');
        } catch (e) {
            // No worries
        }
    };

    return (
        <Button className={classNames} to={url?.replaceAll('&amp;', '&')} onClick={onClick} {...props}>
            <span dangerouslySetInnerHTML={{ __html: text }} />
        </Button>
    );
};
