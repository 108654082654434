import { useState } from 'react';
import clsx from 'clsx';
import { KYCResponse } from '@/queries/kyc-survey';
import { Checkbox } from '../../forms/components/checkbox';
import { FieldsType, Labels } from './types';
import { Button } from '../../typography/button/button';
import { Modal } from '../../modal/modal';
import { useBankOpenSavingsForm } from '@/hooks/use-bank-open-savings-form';

type Props = {
    labels: Labels;
    kycResponse: KYCResponse | undefined;
    handleChange: (value: string | boolean, name: keyof FieldsType) => void;
    setStep: (step: number) => void;
    depositGuaranteePopupText: { children: string };
    kycValues: Record<string, Record<string, string[]>>;
    values: FieldsType;
};

export const StepFour = ({ labels, kycResponse, handleChange, values, depositGuaranteePopupText, kycValues }: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const { submit } = useBankOpenSavingsForm();
    const [isLoading, setIsLoading] = useState(false);
    const [showError, setShowError] = useState(false);

    const readInformation = () => {
        setIsOpen(false);
        handleChange(true, 'acceptDepositGuarantee');
    };

    const documents = kycResponse?.result.documents;

    const onSubmit = async () => {
        setIsLoading(true);
        const response = await submit(values, kycValues);

        if (response?.statusCode === 400 || response?.message === 'Validation failed') {
            setIsLoading(false);
            setShowError(true);
        }
    };

    return (
        <>
            <div className="r-mt-4 r-space-y-2">
                <p className="r-text-xl r-font-bold r-text-primary-400">3. {labels.formStepFourTitle}</p>
                <p>{labels.formStepFourDescription}</p>
                <div className="r-border-b r-pb-10 r-pt-4">
                    <Checkbox
                        onChange={v => handleChange(v, 'acceptCommonTerms')}
                        checked={values.acceptCommonTerms}
                        label={
                            <>
                                <p className="styled-link">
                                    {labels.signCommonTermsCheckboxText}{' '}
                                    <a href={documents?.savingsAccountInformation} target="_blank" rel="noreferrer">
                                        {labels.signCommonTermsLinkText}
                                    </a>
                                    {' & '}
                                    <a href={documents?.commonTermsSavingsAccount} target="_blank" rel="noreferrer">
                                        {labels.signCommonTermsSecondLinkText}
                                    </a>
                                </p>
                            </>
                        }
                    />
                </div>
                <div className="r-border-b r-pb-10 r-pt-6">
                    <Checkbox
                        onChange={() => setIsOpen(true)}
                        checked={values.acceptDepositGuarantee}
                        label={
                            <>
                                <p className="styled-link">
                                    {labels.signDepositGuaranteeCheckboxText}{' '}
                                    <a href={documents?.depositGuarantee} target="_blank" rel="noreferrer">
                                        {labels.signDepositGuaranteeLinkText}
                                    </a>
                                </p>
                            </>
                        }
                    />
                </div>
            </div>
            <div
                className={clsx('r-mt-8 r-border-l-8 r-border-red-600 r-p-2 r-pl-4 r-text-xl r-font-bold', {
                    'r-hidden': !showError,
                })}
            >
                {labels.signErrorMessage}
            </div>
            <Button fullWidth onClick={() => onSubmit()} disabled={!values.acceptCommonTerms || !values.acceptDepositGuarantee || isLoading}>
                <div className="r-flex r-w-full r-space-x-2">
                    <p>{labels.submit}</p>
                    <img className="r-justify-self-end" src="/assets/icons/bankid-se.svg" />
                </div>
            </Button>
            <div className="r-space-y-0">
                <Modal
                    open={isOpen}
                    disableBackdropClick
                    onClose={() => setIsOpen(false)}
                    actions={
                        <div className="r-flex r-w-full r-flex-col r-space-y-2 r-py-2 md:r-flex-row md:r-space-x-6 md:r-space-y-0">
                            <Button onClick={readInformation} fullWidth className="r-w-full">
                                {labels.signDepositGuaranteeButtonText}
                            </Button>
                        </div>
                    }
                >
                    <div className="r-max-w-[600px]" dangerouslySetInnerHTML={{ __html: depositGuaranteePopupText.children }} />
                </Modal>
            </div>
        </>
    );
};
