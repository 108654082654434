import clsx from 'clsx';
import { useEffect, useId, useRef, useState } from 'react';
import { useLocalization } from '@/hooks/use-localization';

export type GenericFieldProps = {
    fieldName?: string;
    label?: string;
    required?: boolean;
    errorMessage?: string;
    infoText?: string;
    placeholder?: string;
    inputClassName?: string;
    maxLength?: number;
    type?: string;
    value?: string | number;
    disabled?: boolean;
    ariaLabel?: string;
    inputMode?: string;
    validation?: (value: string) => boolean;
    onChange: (value: string) => void;
    onBlur?: (value: string) => void;
    min?: number | string;
    max?: number | string;
    rows?: number;
    isValidated?: React.Dispatch<React.SetStateAction<boolean>>;
    showRequired?: boolean;
    suffix?: string;
    grouped?: boolean | 'top' | 'middle' | 'bottom' | '';
};

type ExtendedFieldProps = {
    list: { name: string; email: string }[];
    dropText: string;
};

export type FieldProps = GenericFieldProps & Partial<ExtendedFieldProps>;

export const Field = ({
    fieldName = '',
    required = false,
    infoText = '',
    errorMessage = '',
    placeholder = '',
    label = '',
    maxLength,
    type = 'text',
    value = '',
    disabled = false,
    ariaLabel = '',
    inputMode = '',
    validation,
    onChange,
    onBlur,
    min,
    max,
    rows = 5,
    inputClassName = '',
    isValidated = undefined,
    showRequired = false,
    suffix = '',
    grouped = false,
}: FieldProps) => {
    const { getLanguage } = useLocalization();
    const [touched, setTouched] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const fieldRef = useRef<HTMLInputElement>(null);
    const htmlName = fieldName ? fieldName?.replace(/[^a-z0-9-_]+/g, '').toLocaleLowerCase() : useId();

    useEffect(() => {
        if (validation && value && isValidated) {
            isValidated(validation(value.toString()));
        }

        if (!touched || !validation) {
            setShowError(false);
            return;
        }

        try {
            const isValid = validation(value.toString());
            setShowError(!isValid);
            isValidated?.(isValid);
        } catch (error) {
            throw new Error(error as never); //TODO FIX ERROR HANDLING
        }
    }, [touched, value, validation, isValidated]);

    const determineInputMode = () => {
        const inputType = inputMode || (typeof validation === 'string' ? validation : 'text');

        switch (inputType) {
            case 'socialSecurityNumber':
                if (getLanguage() === 'fi') {
                    return 'text';
                }
                return 'numeric';
            case 'text':
                return 'text';
            case 'email':
                return 'email';
            case 'phone':
                return 'tel';
            case 'url':
                return 'url';
            case 'number':
            case 'numeric':
                return 'numeric';
            case 'search':
                return 'search';
            default:
                return 'text';
        }
    };

    const onFieldChange = (value: string) => {
        if (onChange) {
            onChange(value);
        }
    };

    const onFieldBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const targetElement = event.target as HTMLInputElement | HTMLTextAreaElement;
        setTouched(!!targetElement.value);
        setIsFocused(false);

        if (onBlur) {
            onBlur(targetElement.value);
        }
    };

    return (
        <label htmlFor={htmlName} className="r-relative r-grid r-w-full r-grid-cols-input r-grid-rows-input r-overflow-hidden">
            <div
                className={clsx(
                    'r-col-auto r-row-start-2 r-flex r-border r-border-gray-400 r-transition-all r-duration-500 empty:r-hidden',
                    {
                        'r-rounded-xl': !grouped,
                        'r-border-b-0': grouped === 'middle' || grouped === 'top',
                        'r-rounded-t-xl': grouped === 'top',
                        'r-rounded-b-xl': grouped === 'bottom',
                        'r-text-gray-600': disabled,
                    },
                    disabled ? 'r-bg-gray-100' : 'r-bg-white'
                )}
            >
                {type === 'textarea' ? (
                    <textarea
                        tabIndex={0}
                        id={htmlName}
                        className={clsx(
                            'r-block r-w-full r-resize-none r-rounded-xl r-border-0 r-px-4 r-pb-1 r-pt-6 focus-within:r-outline-0 focus:r-outline-0 focus-visible:r-outline-0 disabled:r-bg-gray-100'
                        )}
                        name={htmlName}
                        required={required}
                        placeholder={placeholder}
                        title={infoText}
                        maxLength={maxLength}
                        disabled={disabled}
                        aria-label={ariaLabel}
                        aria-labelledby={htmlName}
                        inputMode={determineInputMode()}
                        autoComplete="off"
                        onChange={({ target }) => onFieldChange(target.value)}
                        onFocus={() => setIsFocused(true)}
                        onBlur={onFieldBlur}
                        rows={rows}
                        value={value}
                    />
                ) : (
                    <div className="r-relative r-w-full">
                        <input
                            tabIndex={0}
                            id={htmlName}
                            ref={fieldRef}
                            className={clsx(
                                'r-block r-w-full r-rounded-xl r-border-0 r-px-4 r-pb-1 r-pt-6 focus-within:r-outline-0 focus-within:r-ring-0 focus:r-outline-0 focus-visible:r-outline-0 disabled:r-bg-gray-100',
                                inputClassName
                            )}
                            name={htmlName}
                            required={required}
                            placeholder={placeholder}
                            title={infoText}
                            maxLength={maxLength}
                            type={type}
                            disabled={disabled}
                            aria-label={ariaLabel}
                            aria-labelledby={htmlName}
                            inputMode={determineInputMode()}
                            autoComplete="off"
                            onChange={({ target }) => onFieldChange(target.value)}
                            onBlur={onFieldBlur}
                            value={value}
                            min={min}
                            max={max}
                            onFocus={() => setIsFocused(true)}
                        />
                        {suffix && <span className="r-absolute r-inset-y-0 r-right-3 r-flex r-items-center r-text-gray-500">{suffix}</span>}
                    </div>
                )}

                {validation ? (
                    <span className="r-w-6 r-items-start r-pr-2 r-pt-[1.1rem]">
                        {touched ? showError ? <img src="/assets/icons/field-error.svg" /> : <img src="/assets/icons/field-checkmark.svg" /> : null}
                    </span>
                ) : null}

                {infoText && (
                    <span className="r-grid r-w-[2.5rem] r-cursor-pointer r-place-content-center" onClick={() => setShowInfo(!showInfo)}>
                        <img className="r-w-[18px]" src="/assets/icons/field-help.svg" />
                    </span>
                )}
            </div>
            {showInfo && !showError ? (
                <span
                    className={clsx('r-row-start-3 r-bg-slate-200 r-p-4 r-text-sm', {
                        'r-mt-2 r-rounded-lg': !grouped || grouped === 'bottom',
                        'r-border r-border-x-gray-400': grouped === 'top' || grouped === 'middle',
                    })}
                >
                    {infoText}
                </span>
            ) : null}
            {errorMessage && showError ? (
                <span className={clsx('r-row-start-4 r-my-2 r-rounded-xl r-border-gray-400 r-bg-warning r-p-4 r-text-sm r-text-white')}>
                    {errorMessage}
                </span>
            ) : null}
            <span
                onClick={() => fieldRef?.current?.focus()}
                className={clsx('r-absolute r-left-4 r-h-4 r-whitespace-nowrap r-leading-none r-text-gray-500 r-transition-all', {
                    'r-top-2 r-text-xs': isFocused || !!value,
                    'r-text-md r-top-5': !value && !isFocused,
                })}
            >
                {label || ''} {required || showRequired ? '*' : ''}
            </span>
        </label>
    );
};
