import clsx from 'clsx';
import { useId } from 'react';

export type CheckboxProps = {
    label?: string | React.ReactNode;
    name?: string;
    cRef?: React.Ref<HTMLInputElement>;
    checked?: boolean;
    onChange: (checked: boolean) => void;
    value?: string | number;
    labelAlignment?: 'top' | 'center';
    type?: 'checkbox' | 'radio';
    disabled?: boolean;
    deselectable?: boolean;
};

export const Checkbox = ({
    onChange,
    label,
    cRef,
    checked,
    name,
    value,
    labelAlignment,
    type = 'checkbox',
    disabled = false,
    deselectable = false,
}: CheckboxProps) => {
    const htmlName = name ? name?.replace(/[^a-z0-9-_]/g, '').toLocaleLowerCase() : useId();

    const onChangeCallback = () => {
        if (disabled) {
            return;
        }

        onChange(type === 'radio' ? true : !checked);
    };

    return (
        <label
            htmlFor={htmlName}
            data-component="checkbox"
            className={clsx('r-flex r-gap-4 hover:r-cursor-pointer', {
                'hover:r-cursor-pointer': !disabled,
                'r-items-center': labelAlignment === 'center',
            })}
        >
            <input
                className="r-h-6 r-w-6 r-flex-shrink-0 r-border-none r-accent-primary-300 focus-visible:r-rounded-full focus-visible:r-outline-none focus-visible:r-ring-1 focus-visible:r-ring-blue-800 focus-visible:r-ring-offset-2"
                ref={cRef}
                id={htmlName}
                name={htmlName}
                type={type}
                value={value}
                checked={checked}
                disabled={disabled}
                onClick={() => {
                    if (type === 'radio' && deselectable && checked) {
                        onChange(false);
                    }
                }}
                onChange={onChangeCallback}
            />
            {label}
        </label>
    );
};
