import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

type SubMenuItemProps = {
    title: string;
    children?: React.ReactNode;
    to: string;
    setShowMenu: (showMenu: boolean) => void;
};

export const SubMenuItem = ({ title, children, to, setShowMenu }: SubMenuItemProps) => {
    const [isOpen, setIsOpen] = useState(() => {
        if (typeof window === 'undefined') return false;
        const storedState = window.localStorage.getItem(`subMenuItem-${title}`);
        return storedState === 'open';
    });

    useEffect(() => {
        if (isOpen) {
            window.localStorage.setItem(`subMenuItem-${title}`, 'open');
        } else {
            window.localStorage.removeItem(`subMenuItem-${title}`);
        }
    }, [isOpen]);

    return (
        <div className={` ${isOpen && children ? 'r-pb-4' : ''}`}>
            <div className="r-mb-3 r-flex r-justify-between">
                <Link to={to} className="r-text-lg r-font-medium r-text-gray-600" onClick={() => setShowMenu(false)}>
                    {title}
                </Link>
                {children && <img src="/assets/icons/close.svg" className="r-h-7 r-w-7" alt="" onClick={() => setIsOpen(!isOpen)} />}
            </div>
            {isOpen && children && <div className="r-ml-5 r-space-y-6 r-pt-2">{children}</div>}
        </div>
    );
};
