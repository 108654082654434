import clsx from 'clsx';
import { useState, useMemo, useEffect, useRef } from 'react';
import { useOutsideClick } from '@/hooks/use-outside-click';

export type CustomSelectProps = {
    options: { value: string | number; name: string }[];
    value: string | number;
    onChange: (value: string | number) => void;
    label?: string;
    infoText?: string;
    searchable?: boolean;
    required?: boolean;
    placeholder?: string;
    disabled?: boolean;
    dropdownClass?: clsx.ClassValue;
};

export const CustomSelect = ({
    options,
    value,
    onChange,
    label = '',
    infoText,
    searchable = false,
    required = false,
    disabled = false,
    dropdownClass = '',
}: CustomSelectProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [showInfo, setShowInfo] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [selectValue, setSelectValue] = useState(value);
    const [focusedItemIndex, setFocusedItemIndex] = useState<number | null>(null);
    const selectRef = useOutsideClick(() => setIsFocused(false));

    const selectOptions = useMemo(() => {
        const lowerCaseSearchValue = searchValue.toLocaleLowerCase();
        return lowerCaseSearchValue !== '' ? options.filter(o => o.name.toLocaleLowerCase().includes(lowerCaseSearchValue)) : options;
    }, [options, searchValue]);

    const currentValue = useMemo(() => (value ? selectOptions.find(option => option.value === value)?.name : ''), [value, selectOptions]);

    const keyDown = (e: KeyboardEvent) => {
        switch (e.key) {
            case 'ArrowDown':
                e.preventDefault();
                if (focusedItemIndex === null) {
                    setFocusedItemIndex(0);
                    return;
                }

                if (focusedItemIndex !== null && focusedItemIndex < selectOptions.length) {
                    setFocusedItemIndex(focusedItemIndex + 1);
                }
                break;

            case 'ArrowUp':
                e.preventDefault();
                if (focusedItemIndex === null) {
                    setFocusedItemIndex(selectOptions.length - 1);
                    return;
                }

                if (focusedItemIndex !== null && focusedItemIndex < selectOptions.length) {
                    setFocusedItemIndex(focusedItemIndex - 1);
                }
                break;

            case 'Enter':
                if (focusedItemIndex !== null) {
                    const option = selectOptions[focusedItemIndex];
                    onSelectValue(option?.value || '');
                }
                break;
        }
    };

    useEffect(() => {
        if (isFocused && searchable) {
            inputRef?.current?.focus();
        }

        document.addEventListener('keydown', keyDown);

        return () => {
            document.removeEventListener('keydown', keyDown);
        };
    }, [isFocused, searchable]);

    const onSelectValue = (value: string | number) => {
        setIsFocused(false);
        setSearchValue('');
        setSelectValue(value);
        onChange(value);
    };

    const handleOptionKeyDown = (e: KeyboardEvent, value: string) => {
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            onSelectValue(value);
        }
    };

    return (
        <div
            role="combobox"
            aria-haspopup="listbox"
            data-component="custom-select"
            className="r-relative r-rounded-xl"
            aria-expanded={isFocused && !disabled}
        >
            <div
                tabIndex={0}
                className={clsx('r-relative r-rounded-xl', {
                    'r-cursor-pointer': !disabled,
                })}
            >
                {label ? (
                    <span
                        onClick={e => {
                            e.stopPropagation();
                            setIsFocused(true);
                        }}
                        className={clsx('r-absolute r-left-4 r-z-10 r-leading-none r-text-gray-500 r-transition-all', {
                            'r-top-2 r-text-xs': !!value || (isFocused && searchable),
                            'r-text-md r-top-[1.15rem]': !value && (!isFocused || !searchable),
                        })}
                    >
                        {label} {required ? '*' : ''}
                    </span>
                ) : null}
                <div
                    onClick={e => {
                        e.stopPropagation();
                        setIsFocused(true);
                    }}
                    className={clsx(
                        'r-pointer r-block r-w-full r-rounded-xl r-border r-border-gray-400 r-px-4 r-pb-1 r-pt-6 focus-within:r-outline-0 focus:r-outline-0 focus-visible:r-outline-0',
                        {
                            'r-text-transparent focus:r-outline-none': !searchable && !value,
                            'r-bg-gray-100 r-text-gray-600': disabled,
                            'r-bg-white': !disabled,
                        }
                    )}
                >
                    {searchable && isFocused && !disabled ? (
                        <input
                            ref={inputRef}
                            value={searchValue}
                            disabled={disabled}
                            className="r-block r-outline-none"
                            onChange={({ target }) => setSearchValue(target.value)}
                        />
                    ) : (
                        <span className="r-block r-min-h-6">{currentValue}</span>
                    )}
                </div>
                <img
                    width={18}
                    height={18}
                    src="/assets/icons/field-help.svg"
                    onClick={() => setShowInfo(!showInfo)}
                    className={clsx('r-absolute r-right-[10px] r-top-[16px]', {
                        'r-hidden': !infoText,
                    })}
                />
                <img
                    width={10}
                    height={10}
                    src="/assets/icons/select-arrow.svg"
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        setIsFocused(true);
                    }}
                    className={clsx('r-absolute r-top-[1.35rem]', infoText ? 'r-right-9' : 'r-right-[10px]', {
                        'r-rotate-180': !isFocused || disabled,
                    })}
                />
                {showInfo ? <span className="r-mt-2 r-block r-rounded-lg r-bg-slate-200 r-p-4 r-text-sm">{infoText}</span> : null}
            </div>
            {isFocused && !disabled ? (
                <div
                    role="listbox"
                    ref={selectRef}
                    className={clsx(
                        'r-absolute r-left-0 r-right-0 r-z-20 r-mt-2 r-max-h-[26rem] r-overflow-auto r-rounded-xl r-border r-border-gray-400 r-bg-white r-shadow-lg',
                        dropdownClass
                    )}
                >
                    {selectOptions.map(({ value, name }, index) => (
                        <button
                            key={index}
                            onClick={() => onSelectValue(value as string)}
                            onKeyDown={e => handleOptionKeyDown(e as unknown as KeyboardEvent, value as string)}
                            className={clsx(
                                'r-block r-w-full r-cursor-pointer r-border-b r-border-b-gray-400 r-p-4 r-text-left first:r-rounded-t-xl last:r-rounded-b-xl last:r-border-b-0 hover:r-bg-gray-100 focus:r-bg-gray-100',
                                {
                                    'r-font-semibold': value === selectValue,
                                    'r-bg-gray-100': focusedItemIndex === index,
                                }
                            )}
                        >
                            {name}
                        </button>
                    ))}
                </div>
            ) : null}
        </div>
    );
};
