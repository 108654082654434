import clsx from 'clsx';

type ProgressBarProps = {
    step: number;
    totalSteps: number;
    oldSkool?: boolean;
    label?: string;
};

export const ProgressBar = ({ step, totalSteps, oldSkool = false, label = '' }: ProgressBarProps) => {
    const getClassesForStep = (currentStep: number): string => {
        if (currentStep < step) {
            return 'r-bg-primary';
        }

        if (currentStep === step) {
            return clsx('r-bg-white', {
                'r-border-primary r-border-[3px]': !oldSkool,
                'r-border-red-800 r-text-red-800 r-border-2': oldSkool,
            });
        }

        return clsx('r-bg-[#adb5bd] r-text-white');
    };

    const renderItem = (currentStep: number, classNames = '', label = '') => (
        <div
            key={currentStep}
            className={clsx(
                'r-grid r-place-items-center r-rounded-full',
                getClassesForStep(currentStep),
                {
                    '-r-mt-[16px] r-h-[35px] r-w-[35px]': !oldSkool,
                    '-r-mt-[12px] r-h-[24px] r-w-[24px]': oldSkool,
                    '-r-mt-[12px] r-h-[24px] r-w-[80px] r-rounded-md': label,
                },
                classNames
            )}
        >
            {currentStep < step ? <img className="r-w-4" src="/assets/icons/checkbox.svg" alt="checkbox" /> : label || currentStep}
        </div>
    );

    if (totalSteps < 1) {
        return null;
    }

    return (
        <div
            data-component="progress-bar"
            className={clsx('r-my-2 r-flex r-w-full r-place-content-between r-bg-[#adb5bd]', {
                'r-h-1 r-text-xl': !oldSkool,
                'r-h-px r-text-sm': oldSkool,
            })}
        >
            {Array.from({ length: totalSteps }, (_, index) => {
                if (index === 0) {
                    return renderItem(index + 1, '-r-ml-1');
                } else if (index === totalSteps - 1 && label) {
                    return renderItem(index + 1, '-r-mr-1', label);
                } else {
                    return renderItem(index + 1);
                }
            })}
        </div>
    );
};
