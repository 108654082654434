import { Field } from '@/isomorphic/components/forms/components/field';
import { validators } from '@/hooks/use-validation';
import { ApiUser } from './api-user';
import { FieldGroup } from './field-group';
import { PropsType, SignatoryType } from '../types';

type Props = {
    signatory: SignatoryType;
    getLabel: PropsType['getLabel'];
    onUpdate: (args: Partial<SignatoryType>) => void;
};

export const Signatory = ({ signatory, getLabel, onUpdate }: Props) => (
    <div className="r-space-y-4 r-border-b r-border-b-gray-200 r-py-6">
        <ApiUser {...signatory} label={getLabel('companyPrincipal')} />
        <FieldGroup>
            <Field
                grouped="top"
                label={getLabel('phone')}
                value={signatory.phoneNumber}
                validation={value => validators.swedishPhone(value)}
                onChange={value => onUpdate({ phoneNumber: value })}
            />
            <Field
                grouped="bottom"
                value={signatory.email}
                label={getLabel('email')}
                validation={value => validators.email(value)}
                onChange={value => onUpdate({ email: value })}
            />
        </FieldGroup>
    </div>
);
