import { PropsWithChildren, useEffect } from 'react';
import { Link } from 'react-router-dom';
import imgUrl from '@/assets/logos/new/resurs-logo-with-symbol-2022.svg';
import { VerticalWave, Wave } from './svg';
import clsx from 'clsx';

type Props = PropsWithChildren & {
    mobileImage?: string;
    mobileImageHeader?: string;
    desktopImage: string;
};

export const LoginOverlay = ({ children, mobileImage, mobileImageHeader, desktopImage }: Props) => {
    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, []);

    return (
        <div className="r-fixed r-inset-0 r-z-[2000] r-h-screen r-overflow-auto r-bg-white">
            <Link to="/" target="_blank" className="max-sm:r-block max-sm:r-p-4 sm:r-absolute sm:r-left-4 sm:r-top-4">
                <img src={imgUrl} className="r-w-32" />
            </Link>
            <div
                className={clsx('r-relative r-grid r-bg-cover r-bg-center r-bg-no-repeat sm:r-hidden', mobileImageHeader ? 'r-h-80' : 'r-h-64')}
                style={{
                    backgroundImage: `url("${mobileImage}")`,
                }}
            >
                {mobileImageHeader ? (
                    <h3 className="r-w-64 r-pl-4 r-pt-12 r-text-3xl r-font-semibold r-leading-normal r-text-white">{mobileImageHeader}</h3>
                ) : null}
                <div className="r-absolute -r-bottom-px r-w-full">
                    <Wave />
                </div>
            </div>
            <div className="max-sm:r-pt-8 sm:r-flex sm:r-h-screen sm:r-gap-8">
                <div className="r-bg-white r-px-4 max-sm:r-pb-20 sm:r-flex sm:r-w-1/2 sm:r-flex-col sm:r-items-center sm:r-justify-center">
                    <div className="sm:r-w-full lg:r-w-[450px]">{children}</div>
                </div>
                <div className="max-sm:r-hidden sm:r-relative sm:r-w-1/2">
                    <img src={desktopImage} alt="Background" className="r-absolute r-inset-0 r-h-full r-w-full r-object-cover" />
                    <VerticalWave />
                </div>
            </div>
        </div>
    );
};
