import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useGlobalQueries } from '@/hooks/use-global-queries';

export type WidgetType =
    | 'green-loan-mortgage'
    | 'green-loan-private'
    | 'my-economy'
    | 'consumerloan-counter-module'
    | 'supreme-card'
    | 'consumerloan-calculator-app';

export type Props = {
    widget: WidgetType;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataAttributes?: Record<string | number, any>;
    next?: boolean;
    className?: string;
    id?: string;
};

function loadModule(version: string, next: boolean) {
    addStyleTag(version, next);
    addScriptTag(version, next);
}

function addStyleTag(version: string, next: boolean) {
    const id = next ? 'resurs-widget-next-css' : 'resurs-widget-css';
    if (document.getElementById(id)) {
        return;
    }

    try {
        const link = document.createElement('link');
        link.id = id;
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = next ? `/style-next.${version}.css` : `/style.${version}.css`;

        document.head.appendChild(link);
    } catch (error) {
        // Oh dear...
    }
}

function addScriptTag(version: string, next: boolean) {
    const id = next ? 'resurs-widget-next-js' : 'resurs-widget-js';
    if (document.getElementById(id)) {
        return;
    }

    // Force a reload if we have already loaded one of these scripts as the
    // combination of both scripts on one page can be break the page.
    // if ((next && document.getElementById('resurs-widget-js')) || (!next && document.getElementById('resurs-widget-next-js'))) {
    //     window.location.href = window.location.pathname;
    // }

    try {
        const script = document.createElement('script');
        script.id = id;
        script.src = next ? `/resurs-widgets-next.${version}.js` : `/resurs-widgets.${version}.js`;

        document.head.appendChild(script);
    } catch (error) {
        // Oh dear...
    }
}

let tries = 0;
const maxTries = 50;
let timeout: NodeJS.Timeout | undefined;

const initWidget = (widget: WidgetType, next: boolean) => {
    if (tries === maxTries) {
        return;
    }
    tries++;

    try {
        if (next) {
            window?.ResursWidgetsNext?.init(widget);
        } else {
            window?.ResursWidgets?.init(widget);
        }
    } catch (error) {
        console.error(error);
    } finally {
        // We need to wait until the widget has been mounted, then
        // we can activate it
        if (!document.getElementById(`${widget}-container`)) {
            timeout = setTimeout(() => initWidget(widget, next), 200);
        } else {
            tries = 0;
        }
    }
};

// See https://gitlab.24hr.se/resurs/resurs-external-modules for more information
// regarding this component and the subsequent modules loaded via it.
export const DynamicWidget = ({ widget, dataAttributes = {}, next = false, className, id }: Props) => {
    const location = useLocation();
    const { getSetting } = useGlobalQueries();

    function getVersion() {
        if (dataAttributes?.settings?.version) {
            return dataAttributes?.settings?.version;
        }

        const version = getSetting(next ? 'consumerloan_version' : 'external_modules_version');
        const defaultVersion = next ? '0.0.24' : '0.1.0';

        return version || defaultVersion;
    }

    useEffect(() => {
        loadModule(getVersion(), next);
        initWidget(widget, next);

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [widget, next, location]);

    useEffect(() => {
        // Sometimes, the external widgets will just BORK and stop working.
        // This is a workaround to re-instantiate borked widgets after a timeout.
        const intervalTimout = setInterval(() => {
            initWidget(widget, next);
        }, 1000 * 250);

        return () => {
            clearTimeout(intervalTimout);
        };
    }, []);

    if (dataAttributes?.parentProps) {
        delete dataAttributes?.parentProps;
    }

    return (
        <div
            id={id ? id : `${widget}--anchor`}
            data-component="dynamic-widget"
            key={Date.now()}
            className={className}
            data-widget={widget}
            data-attr={JSON.stringify(dataAttributes)}
        >
            <div className="r-flex r-justify-center r-pt-16">
                <svg className="r-h-12 r-w-12 r-animate-spin r-text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="r-opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                    <path
                        className="r-opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                </svg>
            </div>
        </div>
    );
};
