import { AppContextType } from '@/contexts/app-context';

const COUNTRIES = [
    {
        code: 'ad',
        nameSe: 'Andorra',
        nameNo: 'Andorra',
        nameFi: 'Andorra',
        nameDk: 'Andorra',
    },
    {
        code: 'ae',
        nameSe: 'Förenade Arabemiraten',
        nameNo: 'De forente arabiske emirater',
        nameFi: 'Arabiemiirikunnat',
        nameDk: 'Forenede Arabiske Emirater',
    },
    {
        code: 'af',
        nameSe: 'Afghanistan',
        nameNo: 'Afghanistan',
        nameFi: 'Afganistan',
        nameDk: 'Afghanistan',
    },
    {
        code: 'ag',
        nameSe: 'Antigua och Barbuda',
        nameNo: 'Antigua og Barbuda',
        nameFi: 'Antigua ja Barbuda',
        nameDk: 'Antigua og Barbuda',
    },
    {
        code: 'al',
        nameSe: 'Albanien',
        nameNo: 'Albania',
        nameFi: 'Albania',
        nameDk: 'Albanien',
    },
    {
        code: 'am',
        nameSe: 'Armenien',
        nameNo: 'Armenia',
        nameFi: 'Armenia',
        nameDk: 'Armenien',
    },
    {
        code: 'ao',
        nameSe: 'Angola',
        nameNo: 'Angola',
        nameFi: 'Angola',
        nameDk: 'Angola',
    },
    {
        code: 'ar',
        nameSe: 'Argentina',
        nameNo: 'Argentina',
        nameFi: 'Argentiina',
        nameDk: 'Argentina',
    },
    {
        code: 'at',
        nameSe: 'Österrike',
        nameNo: 'Østerrike',
        nameFi: 'Itävalta',
        nameDk: 'Østrig',
    },
    {
        code: 'au',
        nameSe: 'Australien',
        nameNo: 'Australia',
        nameFi: 'Australia',
        nameDk: 'Australien',
    },
    {
        code: 'az',
        nameSe: 'Azerbajdzjan',
        nameNo: 'Aserbajdsjan',
        nameFi: 'Azerbaidžan',
        nameDk: 'Aserbajdsjan',
    },
    {
        code: 'ba',
        nameSe: 'Bosnien och Hercegovina',
        nameNo: 'Bosnia-Hercegovina',
        nameFi: 'Bosnia ja Hertsegovina',
        nameDk: 'Bosnien-Hercegovina',
    },
    {
        code: 'bb',
        nameSe: 'Barbados',
        nameNo: 'Barbados',
        nameFi: 'Barbados',
        nameDk: 'Barbados',
    },
    {
        code: 'bd',
        nameSe: 'Bangladesh',
        nameNo: 'Bangladesh',
        nameFi: 'Bangladesh',
        nameDk: 'Bangladesh',
    },
    {
        code: 'be',
        nameSe: 'Belgien',
        nameNo: 'Belgia',
        nameFi: 'Belgia',
        nameDk: 'Belgien',
    },
    {
        code: 'bf',
        nameSe: 'Burkina Faso',
        nameNo: 'Burkina Faso',
        nameFi: 'Burkina Faso',
        nameDk: 'Burkina Faso',
    },
    {
        code: 'bg',
        nameSe: 'Bulgarien',
        nameNo: 'Bulgaria',
        nameFi: 'Bulgaria',
        nameDk: 'Bulgarien',
    },
    {
        code: 'bh',
        nameSe: 'Bahrain',
        nameNo: 'Bahrain',
        nameFi: 'Bahrain',
        nameDk: 'Bahrain',
    },
    {
        code: 'bi',
        nameSe: 'Burundi',
        nameNo: 'Burundi',
        nameFi: 'Burundi',
        nameDk: 'Burundi',
    },
    {
        code: 'bj',
        nameSe: 'Benin',
        nameNo: 'Benin',
        nameFi: 'Benin',
        nameDk: 'Benin',
    },
    {
        code: 'bn',
        nameSe: 'Brunei',
        nameNo: 'Brunei',
        nameFi: 'Brunei',
        nameDk: 'Brunei',
    },
    {
        code: 'bo',
        nameSe: 'Bolivia',
        nameNo: 'Bolivia',
        nameFi: 'Bolivia',
        nameDk: 'Bolivia',
    },
    {
        code: 'br',
        nameSe: 'Brasilien',
        nameNo: 'Brasil',
        nameFi: 'Brasilia',
        nameDk: 'Brasilien',
    },
    {
        code: 'bs',
        nameSe: 'Bahamas',
        nameNo: 'Bahamas',
        nameFi: 'Bahama',
        nameDk: 'Bahamas',
    },
    {
        code: 'bt',
        nameSe: 'Bhutan',
        nameNo: 'Bhutan',
        nameFi: 'Bhutan',
        nameDk: 'Bhutan',
    },
    {
        code: 'bw',
        nameSe: 'Botswana',
        nameNo: 'Botswana',
        nameFi: 'Botswana',
        nameDk: 'Botswana',
    },
    {
        code: 'by',
        nameSe: 'Belarus',
        nameNo: 'Belarus',
        nameFi: 'Valko-Venäjä',
        nameDk: 'Hviderusland',
    },
    {
        code: 'bz',
        nameSe: 'Belize',
        nameNo: 'Belize',
        nameFi: 'Belize',
        nameDk: 'Belize',
    },
    {
        code: 'ca',
        nameSe: 'Kanada',
        nameNo: 'Canada',
        nameFi: 'Kanada',
        nameDk: 'Canada',
    },
    {
        code: 'cd',
        nameSe: 'Kongo-Kinshasa',
        nameNo: 'Kongo - Den demokratiske republikken',
        nameFi: 'Kongon demokraattinen tasavalta',
        nameDk: 'Demokratiske Republik Congo',
    },
    {
        code: 'cf',
        nameSe: 'Centralafrikanska republiken',
        nameNo: 'Den sentralafrikanske republikk',
        nameFi: 'Keski-Afrikan tasavalta',
        nameDk: 'Centralafrikanske Republik',
    },
    {
        code: 'cg',
        nameSe: 'Kongo-Brazzaville',
        nameNo: 'Kongo - Republikken',
        nameFi: 'Kongon tasavalta',
        nameDk: 'Congo',
    },
    {
        code: 'ch',
        nameSe: 'Schweiz',
        nameNo: 'Sveits',
        nameFi: 'Sveitsi',
        nameDk: 'Schweiz',
    },
    {
        code: 'ci',
        nameSe: 'Elfenbenskusten',
        nameNo: 'Elfenbenskysten',
        nameFi: 'Norsunluurannikko',
        nameDk: 'Elfenbenskysten',
    },
    {
        code: 'cl',
        nameSe: 'Chile',
        nameNo: 'Chile',
        nameFi: 'Chile',
        nameDk: 'Chile',
    },
    {
        code: 'cm',
        nameSe: 'Kamerun',
        nameNo: 'Kamerun',
        nameFi: 'Kamerun',
        nameDk: 'Cameroun',
    },
    {
        code: 'cn',
        nameSe: 'Kina',
        nameNo: 'Kina',
        nameFi: 'Kiina',
        nameDk: 'Kina',
    },
    {
        code: 'co',
        nameSe: 'Colombia',
        nameNo: 'Colombia',
        nameFi: 'Kolumbia',
        nameDk: 'Colombia',
    },
    {
        code: 'cr',
        nameSe: 'Costa Rica',
        nameNo: 'Costa Rica',
        nameFi: 'Costa Rica',
        nameDk: 'Costa Rica',
    },
    {
        code: 'cu',
        nameSe: 'Kuba',
        nameNo: 'Cuba',
        nameFi: 'Kuuba',
        nameDk: 'Cuba',
    },
    {
        code: 'cv',
        nameSe: 'Kap Verde',
        nameNo: 'Kapp Verde',
        nameFi: 'Kap Verde',
        nameDk: 'Kap Verde',
    },
    {
        code: 'cy',
        nameSe: 'Cypern',
        nameNo: 'Kypros',
        nameFi: 'Kypros',
        nameDk: 'Cypern',
    },
    {
        code: 'cz',
        nameSe: 'Tjeckien',
        nameNo: 'Tsjekkia',
        nameFi: 'Tšekki',
        nameDk: 'Tjekkiet',
    },
    {
        code: 'de',
        nameSe: 'Tyskland',
        nameNo: 'Tyskland',
        nameFi: 'Saksa',
        nameDk: 'Tyskland',
    },
    {
        code: 'dj',
        nameSe: 'Djibouti',
        nameNo: 'Djibouti',
        nameFi: 'Djibouti',
        nameDk: 'Djibouti',
    },
    {
        code: 'dk',
        nameSe: 'Danmark',
        nameNo: 'Danmark',
        nameFi: 'Tanska',
        nameDk: 'Danmark',
    },
    {
        code: 'dm',
        nameSe: 'Dominica',
        nameNo: 'Dominica',
        nameFi: 'Dominica',
        nameDk: 'Dominica',
    },
    {
        code: 'do',
        nameSe: 'Dominikanska republiken',
        nameNo: 'Den dominikanske republikk',
        nameFi: 'Dominikaaninen tasavalta',
        nameDk: 'Dominikanske Republik',
    },
    {
        code: 'dz',
        nameSe: 'Algeriet',
        nameNo: 'Algerie',
        nameFi: 'Algeria',
        nameDk: 'Algeriet',
    },
    {
        code: 'ec',
        nameSe: 'Ecuador',
        nameNo: 'Ecuador',
        nameFi: 'Ecuador',
        nameDk: 'Ecuador',
    },
    {
        code: 'ee',
        nameSe: 'Estland',
        nameNo: 'Estland',
        nameFi: 'Viro',
        nameDk: 'Estland',
    },
    {
        code: 'eg',
        nameSe: 'Egypten',
        nameNo: 'Egypt',
        nameFi: 'Egypti',
        nameDk: 'Egypten',
    },
    {
        code: 'er',
        nameSe: 'Eritrea',
        nameNo: 'Eritrea',
        nameFi: 'Eritrea',
        nameDk: 'Eritrea',
    },
    {
        code: 'es',
        nameSe: 'Spanien',
        nameNo: 'Spania',
        nameFi: 'Espanja',
        nameDk: 'Spanien',
    },
    {
        code: 'et',
        nameSe: 'Etiopien',
        nameNo: 'Etiopia',
        nameFi: 'Etiopia',
        nameDk: 'Etiopien',
    },
    {
        code: 'fi',
        nameSe: 'Finland',
        nameNo: 'Finland',
        nameFi: 'Suomi',
        nameDk: 'Finland',
    },
    {
        code: 'fj',
        nameSe: 'Fiji',
        nameNo: 'Fiji',
        nameFi: 'Fidži',
        nameDk: 'Fiji',
    },
    {
        code: 'fm',
        nameSe: 'Mikronesiska federationen',
        nameNo: 'Mikronesiaføderasjonen',
        nameFi: 'Mikronesian liittovaltio',
        nameDk: 'Mikronesien',
    },
    {
        code: 'fr',
        nameSe: 'Frankrike',
        nameNo: 'Frankrike',
        nameFi: 'Ranska',
        nameDk: 'Frankrig',
    },
    {
        code: 'ga',
        nameSe: 'Gabon',
        nameNo: 'Gabon',
        nameFi: 'Gabon',
        nameDk: 'Gabon',
    },
    {
        code: 'gb',
        nameSe: 'Storbritannien',
        nameNo: 'Storbritannia',
        nameFi: 'Yhdistynyt kuningaskunta',
        nameDk: 'Storbritannien',
    },
    {
        code: 'gd',
        nameSe: 'Grenada',
        nameNo: 'Grenada',
        nameFi: 'Grenada',
        nameDk: 'Grenada',
    },
    {
        code: 'ge',
        nameSe: 'Georgien',
        nameNo: 'Georgia',
        nameFi: 'Georgia',
        nameDk: 'Georgien',
    },
    {
        code: 'gh',
        nameSe: 'Ghana',
        nameNo: 'Ghana',
        nameFi: 'Ghana',
        nameDk: 'Ghana',
    },
    {
        code: 'gm',
        nameSe: 'Gambia',
        nameNo: 'Gambia',
        nameFi: 'Gambia',
        nameDk: 'Gambia',
    },
    {
        code: 'gn',
        nameSe: 'Guinea',
        nameNo: 'Guinea',
        nameFi: 'Guinea',
        nameDk: 'Guinea',
    },
    {
        code: 'gq',
        nameSe: 'Ekvatorialguinea',
        nameNo: 'Ekvatorial-Guinea',
        nameFi: 'Päiväntasaajan Guinea',
        nameDk: 'Ækvatorialguinea',
    },
    {
        code: 'gr',
        nameSe: 'Grekland',
        nameNo: 'Hellas',
        nameFi: 'Kreikka',
        nameDk: 'Grækenland',
    },
    {
        code: 'gt',
        nameSe: 'Guatemala',
        nameNo: 'Guatemala',
        nameFi: 'Guatemala',
        nameDk: 'Guatemala',
    },
    {
        code: 'gw',
        nameSe: 'Guinea-Bissau',
        nameNo: 'Guinea-Bissau',
        nameFi: 'Guinea-Bissau',
        nameDk: 'Guinea-Bissau',
    },
    {
        code: 'gy',
        nameSe: 'Guyana',
        nameNo: 'Guyana',
        nameFi: 'Guyana',
        nameDk: 'Guyana',
    },
    {
        code: 'hn',
        nameSe: 'Honduras',
        nameNo: 'Honduras',
        nameFi: 'Honduras',
        nameDk: 'Honduras',
    },
    {
        code: 'hr',
        nameSe: 'Kroatien',
        nameNo: 'Kroatia',
        nameFi: 'Kroatia',
        nameDk: 'Kroatien',
    },
    {
        code: 'ht',
        nameSe: 'Haiti',
        nameNo: 'Haiti',
        nameFi: 'Haiti',
        nameDk: 'Haiti',
    },
    {
        code: 'hu',
        nameSe: 'Ungern',
        nameNo: 'Ungarn',
        nameFi: 'Unkari',
        nameDk: 'Ungarn',
    },
    {
        code: 'id',
        nameSe: 'Indonesien',
        nameNo: 'Indonesia',
        nameFi: 'Indonesia',
        nameDk: 'Indonesien',
    },
    {
        code: 'ie',
        nameSe: 'Irland',
        nameNo: 'Irland',
        nameFi: 'Irlanti',
        nameDk: 'Irland',
    },
    {
        code: 'il',
        nameSe: 'Israel',
        nameNo: 'Israel',
        nameFi: 'Israel',
        nameDk: 'Israel',
    },
    {
        code: 'in',
        nameSe: 'Indien',
        nameNo: 'India',
        nameFi: 'Intia',
        nameDk: 'Indien',
    },
    {
        code: 'iq',
        nameSe: 'Irak',
        nameNo: 'Irak',
        nameFi: 'Irak',
        nameDk: 'Irak',
    },
    {
        code: 'ir',
        nameSe: 'Iran',
        nameNo: 'Iran',
        nameFi: 'Iran',
        nameDk: 'Iran',
    },
    {
        code: 'is',
        nameSe: 'Island',
        nameNo: 'Island',
        nameFi: 'Islanti',
        nameDk: 'Island',
    },
    {
        code: 'it',
        nameSe: 'Italien',
        nameNo: 'Italia',
        nameFi: 'Italia',
        nameDk: 'Italien',
    },
    {
        code: 'jm',
        nameSe: 'Jamaica',
        nameNo: 'Jamaica',
        nameFi: 'Jamaika',
        nameDk: 'Jamaica',
    },
    {
        code: 'jo',
        nameSe: 'Jordanien',
        nameNo: 'Jordan',
        nameFi: 'Jordania',
        nameDk: 'Jordan',
    },
    {
        code: 'jp',
        nameSe: 'Japan',
        nameNo: 'Japan',
        nameFi: 'Japani',
        nameDk: 'Japan',
    },
    {
        code: 'ke',
        nameSe: 'Kenya',
        nameNo: 'Kenya',
        nameFi: 'Kenia',
        nameDk: 'Kenya',
    },
    {
        code: 'kg',
        nameSe: 'Kirgizistan',
        nameNo: 'Kirgisistan',
        nameFi: 'Kirgisia',
        nameDk: 'Kirgisistan',
    },
    {
        code: 'kh',
        nameSe: 'Kambodja',
        nameNo: 'Kambodsja',
        nameFi: 'Kambodža',
        nameDk: 'Cambodja',
    },
    {
        code: 'ki',
        nameSe: 'Kiribati',
        nameNo: 'Kiribati',
        nameFi: 'Kiribati',
        nameDk: 'Kiribati',
    },
    {
        code: 'km',
        nameSe: 'Komorerna',
        nameNo: 'Komorene',
        nameFi: 'Komorit',
        nameDk: 'Comorerne',
    },
    {
        code: 'kn',
        nameSe: 'Saint Kitts och Nevis',
        nameNo: 'Saint Kitts og Nevis',
        nameFi: 'Saint Kitts ja Nevis',
        nameDk: 'Saint Kitts og Nevis',
    },
    {
        code: 'kp',
        nameSe: 'Nordkorea',
        nameNo: 'Nord-Korea',
        nameFi: 'Korean demokraattinen kansantasavalta',
        nameDk: 'Nordkorea',
    },
    {
        code: 'kr',
        nameSe: 'Sydkorea',
        nameNo: 'Sør-Korea',
        nameFi: 'Korean tasavalta',
        nameDk: 'Sydkorea',
    },
    {
        code: 'kw',
        nameSe: 'Kuwait',
        nameNo: 'Kuwait',
        nameFi: 'Kuwait',
        nameDk: 'Kuwait',
    },
    {
        code: 'kz',
        nameSe: 'Kazakstan',
        nameNo: 'Kasakhstan',
        nameFi: 'Kazakstan',
        nameDk: 'Kasakhstan',
    },
    {
        code: 'la',
        nameSe: 'Laos',
        nameNo: 'Laos',
        nameFi: 'Laos',
        nameDk: 'Laos',
    },
    {
        code: 'lb',
        nameSe: 'Libanon',
        nameNo: 'Libanon',
        nameFi: 'Libanon',
        nameDk: 'Libanon',
    },
    {
        code: 'lc',
        nameSe: 'Saint Lucia',
        nameNo: 'Saint Lucia',
        nameFi: 'Saint Lucia',
        nameDk: 'Saint Lucia',
    },
    {
        code: 'li',
        nameSe: 'Liechtenstein',
        nameNo: 'Liechtenstein',
        nameFi: 'Liechtenstein',
        nameDk: 'Liechtenstein',
    },
    {
        code: 'lk',
        nameSe: 'Sri Lanka',
        nameNo: 'Sri Lanka',
        nameFi: 'Sri Lanka',
        nameDk: 'Sri Lanka',
    },
    {
        code: 'lr',
        nameSe: 'Liberia',
        nameNo: 'Liberia',
        nameFi: 'Liberia',
        nameDk: 'Liberia',
    },
    {
        code: 'ls',
        nameSe: 'Lesotho',
        nameNo: 'Lesotho',
        nameFi: 'Lesotho',
        nameDk: 'Lesotho',
    },
    {
        code: 'lt',
        nameSe: 'Litauen',
        nameNo: 'Litauen',
        nameFi: 'Liettua',
        nameDk: 'Litauen',
    },
    {
        code: 'lu',
        nameSe: 'Luxemburg',
        nameNo: 'Luxembourg',
        nameFi: 'Luxemburg',
        nameDk: 'Luxembourg',
    },
    {
        code: 'lv',
        nameSe: 'Lettland',
        nameNo: 'Latvia',
        nameFi: 'Latvia',
        nameDk: 'Letland',
    },
    {
        code: 'ly',
        nameSe: 'Libyen',
        nameNo: 'Libya',
        nameFi: 'Libya',
        nameDk: 'Libyen',
    },
    {
        code: 'ma',
        nameSe: 'Marocko',
        nameNo: 'Marokko',
        nameFi: 'Marokko',
        nameDk: 'Marokko',
    },
    {
        code: 'mc',
        nameSe: 'Monaco',
        nameNo: 'Monaco',
        nameFi: 'Monaco',
        nameDk: 'Monaco',
    },
    {
        code: 'md',
        nameSe: 'Moldavien',
        nameNo: 'Moldova',
        nameFi: 'Moldova',
        nameDk: 'Moldova',
    },
    {
        code: 'me',
        nameSe: 'Montenegro',
        nameNo: 'Montenegro',
        nameFi: 'Montenegro',
        nameDk: 'Montenegro',
    },
    {
        code: 'mg',
        nameSe: 'Madagaskar',
        nameNo: 'Madagaskar',
        nameFi: 'Madagaskar',
        nameDk: 'Madagaskar',
    },
    {
        code: 'mh',
        nameSe: 'Marshallöarna',
        nameNo: 'Marshalløyene',
        nameFi: 'Marshallinsaaret',
        nameDk: 'Marshalløerne',
    },
    {
        code: 'mk',
        nameSe: 'Nordmakedonien',
        nameNo: 'Makedonia - Republikken',
        nameFi: 'Pohjois-Makedonia',
        nameDk: 'Nordmakedonien',
    },
    {
        code: 'ml',
        nameSe: 'Mali',
        nameNo: 'Mali',
        nameFi: 'Mali',
        nameDk: 'Mali',
    },
    {
        code: 'mm',
        nameSe: 'Myanmar',
        nameNo: 'Myanmar',
        nameFi: 'Myanmar',
        nameDk: 'Burma',
    },
    {
        code: 'mn',
        nameSe: 'Mongoliet',
        nameNo: 'Mongolia',
        nameFi: 'Mongolia',
        nameDk: 'Mongoliet',
    },
    {
        code: 'mr',
        nameSe: 'Mauretanien',
        nameNo: 'Mauritania',
        nameFi: 'Mauritania',
        nameDk: 'Mauretanien',
    },
    {
        code: 'mt',
        nameSe: 'Malta',
        nameNo: 'Malta',
        nameFi: 'Malta',
        nameDk: 'Malta',
    },
    {
        code: 'mu',
        nameSe: 'Mauritius',
        nameNo: 'Mauritius',
        nameFi: 'Mauritius',
        nameDk: 'Mauritius',
    },
    {
        code: 'mv',
        nameSe: 'Maldiverna',
        nameNo: 'Maldivene',
        nameFi: 'Malediivit',
        nameDk: 'Maldiverne',
    },
    {
        code: 'mw',
        nameSe: 'Malawi',
        nameNo: 'Malawi',
        nameFi: 'Malawi',
        nameDk: 'Malawi',
    },
    {
        code: 'mx',
        nameSe: 'Mexiko',
        nameNo: 'Mexico',
        nameFi: 'Meksiko',
        nameDk: 'Mexico',
    },
    {
        code: 'my',
        nameSe: 'Malaysia',
        nameNo: 'Malaysia',
        nameFi: 'Malesia',
        nameDk: 'Malaysia',
    },
    {
        code: 'mz',
        nameSe: 'Moçambique',
        nameNo: 'Mosambik',
        nameFi: 'Mosambik',
        nameDk: 'Mozambique',
    },
    {
        code: 'na',
        nameSe: 'Namibia',
        nameNo: 'Namibia',
        nameFi: 'Namibia',
        nameDk: 'Namibia',
    },
    {
        code: 'ne',
        nameSe: 'Niger',
        nameNo: 'Niger',
        nameFi: 'Niger',
        nameDk: 'Niger',
    },
    {
        code: 'ng',
        nameSe: 'Nigeria',
        nameNo: 'Nigeria',
        nameFi: 'Nigeria',
        nameDk: 'Nigeria',
    },
    {
        code: 'ni',
        nameSe: 'Nicaragua',
        nameNo: 'Nicaragua',
        nameFi: 'Nicaragua',
        nameDk: 'Nicaragua',
    },
    {
        code: 'nl',
        nameSe: 'Nederländerna',
        nameNo: 'Nederland',
        nameFi: 'Alankomaat',
        nameDk: 'Holland',
    },
    {
        code: 'no',
        nameSe: 'Norge',
        nameNo: 'Norge',
        nameFi: 'Norja',
        nameDk: 'Norge',
    },
    {
        code: 'np',
        nameSe: 'Nepal',
        nameNo: 'Nepal',
        nameFi: 'Nepal',
        nameDk: 'Nepal',
    },
    {
        code: 'nr',
        nameSe: 'Nauru',
        nameNo: 'Nauru',
        nameFi: 'Nauru',
        nameDk: 'Nauru',
    },
    {
        code: 'nz',
        nameSe: 'Nya Zeeland',
        nameNo: 'New Zealand',
        nameFi: 'Uusi-Seelanti',
        nameDk: 'New Zealand',
    },
    {
        code: 'om',
        nameSe: 'Oman',
        nameNo: 'Oman',
        nameFi: 'Oman',
        nameDk: 'Oman',
    },
    {
        code: 'pa',
        nameSe: 'Panama',
        nameNo: 'Panama',
        nameFi: 'Panama',
        nameDk: 'Panama',
    },
    {
        code: 'pe',
        nameSe: 'Peru',
        nameNo: 'Peru',
        nameFi: 'Peru',
        nameDk: 'Peru',
    },
    {
        code: 'pg',
        nameSe: 'Papua Nya Guinea',
        nameNo: 'Papua Ny-Guinea',
        nameFi: 'Papua-Uusi-Guinea',
        nameDk: 'Papua Ny Guinea',
    },
    {
        code: 'ph',
        nameSe: 'Filippinerna',
        nameNo: 'Filippinene',
        nameFi: 'Filippiinit',
        nameDk: 'Filippinerne',
    },
    {
        code: 'pk',
        nameSe: 'Pakistan',
        nameNo: 'Pakistan',
        nameFi: 'Pakistan',
        nameDk: 'Pakistan',
    },
    {
        code: 'pl',
        nameSe: 'Polen',
        nameNo: 'Polen',
        nameFi: 'Puola',
        nameDk: 'Polen',
    },
    {
        code: 'pt',
        nameSe: 'Portugal',
        nameNo: 'Portugal',
        nameFi: 'Portugali',
        nameDk: 'Portugal',
    },
    {
        code: 'pw',
        nameSe: 'Palau',
        nameNo: 'Palau',
        nameFi: 'Palau',
        nameDk: 'Palau',
    },
    {
        code: 'py',
        nameSe: 'Paraguay',
        nameNo: 'Paraguay',
        nameFi: 'Paraguay',
        nameDk: 'Paraguay',
    },
    {
        code: 'qa',
        nameSe: 'Qatar',
        nameNo: 'Qatar',
        nameFi: 'Qatar',
        nameDk: 'Qatar',
    },
    {
        code: 'ro',
        nameSe: 'Rumänien',
        nameNo: 'Romania',
        nameFi: 'Romania',
        nameDk: 'Rumænien',
    },
    {
        code: 'rs',
        nameSe: 'Serbien',
        nameNo: 'Serbia',
        nameFi: 'Serbia',
        nameDk: 'Serbien',
    },
    {
        code: 'ru',
        nameSe: 'Ryssland',
        nameNo: 'Russland',
        nameFi: 'Venäjä',
        nameDk: 'Rusland',
    },
    {
        code: 'rw',
        nameSe: 'Rwanda',
        nameNo: 'Rwanda',
        nameFi: 'Ruanda',
        nameDk: 'Rwanda',
    },
    {
        code: 'sa',
        nameSe: 'Saudiarabien',
        nameNo: 'Saudi-Arabia',
        nameFi: 'Saudi-Arabia',
        nameDk: 'Saudi-Arabien',
    },
    {
        code: 'sb',
        nameSe: 'Salomonöarna',
        nameNo: 'Salomonøyene',
        nameFi: 'Salomonsaaret',
        nameDk: 'Salomonøerne',
    },
    {
        code: 'sc',
        nameSe: 'Seychellerna',
        nameNo: 'Seychellene',
        nameFi: 'Seychellit',
        nameDk: 'Seychellerne',
    },
    {
        code: 'sd',
        nameSe: 'Sudan',
        nameNo: 'Sudan',
        nameFi: 'Sudan',
        nameDk: 'Sudan',
    },
    {
        code: 'se',
        nameSe: 'Sverige',
        nameNo: 'Sverige',
        nameFi: 'Ruotsi',
        nameDk: 'Sverige',
    },
    {
        code: 'sg',
        nameSe: 'Singapore',
        nameNo: 'Singapore',
        nameFi: 'Singapore',
        nameDk: 'Singapore',
    },
    {
        code: 'si',
        nameSe: 'Slovenien',
        nameNo: 'Slovenia',
        nameFi: 'Slovenia',
        nameDk: 'Slovenien',
    },
    {
        code: 'sk',
        nameSe: 'Slovakien',
        nameNo: 'Slovakia',
        nameFi: 'Slovakia',
        nameDk: 'Slovakiet',
    },
    {
        code: 'sl',
        nameSe: 'Sierra Leone',
        nameNo: 'Sierra Leone',
        nameFi: 'Sierra Leone',
        nameDk: 'Sierra Leone',
    },
    {
        code: 'sm',
        nameSe: 'San Marino',
        nameNo: 'San Marino',
        nameFi: 'San Marino',
        nameDk: 'San Marino',
    },
    {
        code: 'sn',
        nameSe: 'Senegal',
        nameNo: 'Senegal',
        nameFi: 'Senegal',
        nameDk: 'Senegal',
    },
    {
        code: 'so',
        nameSe: 'Somalia',
        nameNo: 'Somalia',
        nameFi: 'Somalia',
        nameDk: 'Somalia',
    },
    {
        code: 'sr',
        nameSe: 'Surinam',
        nameNo: 'Surinam',
        nameFi: 'Suriname',
        nameDk: 'Surinam',
    },
    {
        code: 'ss',
        nameSe: 'Sydsudan',
        nameNo: 'Sør-Sudan',
        nameFi: 'Etelä-Sudan',
        nameDk: 'Sydsudan',
    },
    {
        code: 'st',
        nameSe: 'São Tomé och Príncipe',
        nameNo: 'São Tomé og Príncipe',
        nameFi: 'São Tomé ja Príncipe',
        nameDk: 'São Tomé og Príncipe',
    },
    {
        code: 'sv',
        nameSe: 'El Salvador',
        nameNo: 'El Salvador',
        nameFi: 'El Salvador',
        nameDk: 'El Salvador',
    },
    {
        code: 'sy',
        nameSe: 'Syrien',
        nameNo: 'Syria',
        nameFi: 'Syyria',
        nameDk: 'Syrien',
    },
    {
        code: 'sz',
        nameSe: 'Swaziland',
        nameNo: 'Eswatini',
        nameFi: 'Swazimaa',
        nameDk: 'Swaziland',
    },
    {
        code: 'td',
        nameSe: 'Tchad',
        nameNo: 'Tsjad',
        nameFi: 'Tšad',
        nameDk: 'Tchad',
    },
    {
        code: 'tg',
        nameSe: 'Togo',
        nameNo: 'Togo',
        nameFi: 'Togo',
        nameDk: 'Togo',
    },
    {
        code: 'th',
        nameSe: 'Thailand',
        nameNo: 'Thailand',
        nameFi: 'Thaimaa',
        nameDk: 'Thailand',
    },
    {
        code: 'tj',
        nameSe: 'Tadzjikistan',
        nameNo: 'Tadsjikistan',
        nameFi: 'Tadžikistan',
        nameDk: 'Tadsjikistan',
    },
    {
        code: 'tl',
        nameSe: 'Östtimor',
        nameNo: 'Øst-Timor',
        nameFi: 'Itä-Timor',
        nameDk: 'Østtimor',
    },
    {
        code: 'tm',
        nameSe: 'Turkmenistan',
        nameNo: 'Turkmenistan',
        nameFi: 'Turkmenistan',
        nameDk: 'Turkmenistan',
    },
    {
        code: 'tn',
        nameSe: 'Tunisien',
        nameNo: 'Tunisia',
        nameFi: 'Tunisia',
        nameDk: 'Tunesien',
    },
    {
        code: 'to',
        nameSe: 'Tonga',
        nameNo: 'Tonga',
        nameFi: 'Tonga',
        nameDk: 'Tonga',
    },
    {
        code: 'tr',
        nameSe: 'Turkiet',
        nameNo: 'Tyrkia',
        nameFi: 'Turkki',
        nameDk: 'Tyrkiet',
    },
    {
        code: 'tt',
        nameSe: 'Trinidad och Tobago',
        nameNo: 'Trinidad og Tobago',
        nameFi: 'Trinidad ja Tobago',
        nameDk: 'Trinidad og Tobago',
    },
    {
        code: 'tv',
        nameSe: 'Tuvalu',
        nameNo: 'Tuvalu',
        nameFi: 'Tuvalu',
        nameDk: 'Tuvalu',
    },
    {
        code: 'tz',
        nameSe: 'Tanzania',
        nameNo: 'Tanzania',
        nameFi: 'Tansania',
        nameDk: 'Tanzania',
    },
    {
        code: 'ua',
        nameSe: 'Ukraina',
        nameNo: 'Ukraina',
        nameFi: 'Ukraina',
        nameDk: 'Ukraine',
    },
    {
        code: 'ug',
        nameSe: 'Uganda',
        nameNo: 'Uganda',
        nameFi: 'Uganda',
        nameDk: 'Uganda',
    },
    {
        code: 'us',
        nameSe: 'USA',
        nameNo: 'USA',
        nameFi: 'Yhdysvallat',
        nameDk: 'USA',
    },
    {
        code: 'uy',
        nameSe: 'Uruguay',
        nameNo: 'Uruguay',
        nameFi: 'Uruguay',
        nameDk: 'Uruguay',
    },
    {
        code: 'uz',
        nameSe: 'Uzbekistan',
        nameNo: 'Usbekistan',
        nameFi: 'Uzbekistan',
        nameDk: 'Usbekistan',
    },
    {
        code: 'vc',
        nameSe: 'Saint Vincent och Grenadinerna',
        nameNo: 'Saint Vincent og Grenadinene',
        nameFi: 'Saint Vincent ja Grenadiinit',
        nameDk: 'Saint Vincent og Grenadinerne',
    },
    {
        code: 've',
        nameSe: 'Venezuela',
        nameNo: 'Venezuela',
        nameFi: 'Venezuela',
        nameDk: 'Venezuela',
    },
    {
        code: 'vn',
        nameSe: 'Vietnam',
        nameNo: 'Vietnam',
        nameFi: 'Vietnam',
        nameDk: 'Vietnam',
    },
    {
        code: 'vu',
        nameSe: 'Vanuatu',
        nameNo: 'Vanuatu',
        nameFi: 'Vanuatu',
        nameDk: 'Vanuatu',
    },
    {
        code: 'ws',
        nameSe: 'Samoa',
        nameNo: 'Samoa',
        nameFi: 'Samoa',
        nameDk: 'Samoa',
    },
    {
        code: 'ye',
        nameSe: 'Jemen',
        nameNo: 'Jemen',
        nameFi: 'Jemen',
        nameDk: 'Yemen',
    },
    {
        code: 'za',
        nameSe: 'Sydafrika',
        nameNo: 'Sør-Afrika',
        nameFi: 'Etelä-Afrikka',
        nameDk: 'Sydafrika',
    },
    {
        code: 'zm',
        nameSe: 'Zambia',
        nameNo: 'Zambia',
        nameFi: 'Sambia',
        nameDk: 'Zambia',
    },
    {
        code: 'zw',
        nameSe: 'Zimbabwe',
        nameNo: 'Zimbabwe',
        nameFi: 'Zimbabwe',
        nameDk: 'Zimbabwe',
    },
];

const getCountryKey = (lang: AppContextType['language']) => {
    switch (lang) {
        case 'no':
            return 'nameNo';
        case 'da':
            return 'nameDk';
        case 'fi':
            return 'nameFi';
    }

    return 'nameSe';
};

export const getCountrySelectionOptions = (lang: AppContextType['language']) => {
    const key = getCountryKey(lang);
    const initial = [
        {
            code: '',
            nameSe: ' -- Välj -- ',
            nameNo: ' -- Velg -- ',
            nameFi: ' -- Valita -- ',
            nameDk: ' -- Vælge -- ',
        },
        {
            code: 'se',
            nameSe: 'Sverige',
            nameNo: 'Sverige',
            nameFi: 'Ruotsi',
            nameDk: 'Sverige',
        },
        {
            code: 'dk',
            nameSe: 'Danmark',
            nameNo: 'Danmark',
            nameFi: 'Tanska',
            nameDk: 'Danmark',
        },
        {
            code: 'no',
            nameSe: 'Norge',
            nameNo: 'Norge',
            nameFi: 'Norja',
            nameDk: 'Norge',
        },
        {
            code: 'fi',
            nameSe: 'Finland',
            nameNo: 'Finland',
            nameFi: 'Suomi',
            nameDk: 'Finland',
        },
    ];

    return [...initial, ...COUNTRIES].map(country => ({
        value: country.code,
        label: country[key],
    }));
};
