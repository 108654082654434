import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Button } from '../../typography/button/button';
import { Field } from '../../forms/components/field';
import { useValidation } from '@/hooks/use-validation';
import { AppContextType } from '@/contexts/app-context';
import { FieldsType, Labels } from './types';

type Props = {
    labels: Labels;
    handleChange: (value: string | boolean, name: keyof FieldsType) => void;
    values: FieldsType;
    setStep: (step: number) => void;
    user: AppContextType['user'];
    login: () => void;
};

export const StepOne = ({ labels, handleChange, values, setStep, login, user }: Props) => {
    const { getValidatorByInputName } = useValidation();
    const [disabled, setDisabled] = useState(true);
    const [nextBtnDisabled, setNextBtnDisabled] = useState(true);
    const [mobileValidated, setMobileValidated] = useState(false);
    const [emailValidated, setEmailValidated] = useState(false);

    useEffect(() => {
        if (user?.isAuthenticated) {
            setDisabled(false);
        }
    }, [user]);

    useEffect(() => {
        if (mobileValidated && emailValidated) {
            setNextBtnDisabled(false);
        } else {
            setNextBtnDisabled(true);
        }
    }, [mobileValidated, emailValidated]);

    return (
        <>
            <div className="r-mb-10 r-mt-4 r-space-y-10">
                <p className="r-text-xl r-font-bold r-text-primary-500">1. {labels.formStepOneTitle}</p>
                {disabled && (
                    <Button type="primary" fullWidth onClick={() => login()}>
                        <div className="r-flex r-space-x-1">
                            <p> {labels.formLoginButtonText}</p>
                            <img src="/assets/icons/bankid-se.svg" alt="" />
                        </div>
                    </Button>
                )}
            </div>
            <div
                className={clsx('r-max-w-[560px] r-space-y-4', {
                    'r-pointer-events-none r-opacity-30': disabled,
                })}
            >
                <p>{labels.formStepOneDescription}</p>
                <Field disabled value={values.name} onChange={v => handleChange(v, 'name')} label={`${labels.name} *`} />
                <Field disabled value={values.streetAddress} onChange={v => handleChange(v, 'streetAddress')} label={`${labels.streetAddress} *`} />
                <div className="r-flex r-space-x-6">
                    <div className="r-flex-shrink r-flex-grow" style={{ flexBasis: '30%' }}>
                        <Field disabled value={values.zipCode} onChange={v => handleChange(v, 'zipCode')} label={`${labels.zipCode} *`} />
                    </div>
                    <div className="r-flex-shrink r-flex-grow" style={{ flexBasis: '60%' }}>
                        <Field disabled value={values.city} onChange={v => handleChange(v, 'city')} label={`${labels.city} *`} />
                    </div>
                </div>
                <div>
                    <Field
                        grouped="top"
                        value={values.phoneDay}
                        onChange={v => handleChange(v, 'phoneDay')}
                        infoText={labels.phoneDayInfoText}
                        label={labels.phoneDay}
                        validation={getValidatorByInputName('phone')}
                    />
                    <Field
                        grouped="middle"
                        value={values.phoneMobile}
                        onChange={v => handleChange(v, 'phoneMobile')}
                        infoText={labels.phoneMobileInfoText}
                        label={`${labels.phoneMobile} *`}
                        validation={getValidatorByInputName('phone')}
                        isValidated={setMobileValidated}
                    />
                    <Field
                        grouped="bottom"
                        value={values.contactEmail}
                        onChange={v => handleChange(v, 'contactEmail')}
                        infoText={labels.contactEmailInfoText}
                        label={`${labels.contactEmail} *`}
                        validation={getValidatorByInputName('email')}
                        isValidated={setEmailValidated}
                    />
                </div>
                <Button onClick={() => setStep(2)} fullWidth disabled={nextBtnDisabled} type="tertiary">
                    <div className="r-flex r-items-center r-space-x-4">
                        <p>{labels.continue} 2</p>
                        {/* We need a simple way of modifying the svg colors */}
                        <img width={20} src="/assets/icons/arrow-right.svg" alt="" />
                    </div>
                </Button>
            </div>
        </>
    );
};
