import { Link, useLocation, useNavigate } from 'react-router-dom';
import { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { Icon } from './components/icon';
import { Size } from '@/utils/typography';
import { removeTrailingSlash } from '@/utils/strings';

export type Variant = 'standard' | 'arrow' | 'bankidwhite' | 'bankidnorwaywhite' | 'bankidnorwaypurple';

export type ButtonProps = {
    to?: string | null;
    target?: string;
    type?: 'primary' | 'secondary' | 'secondaryBorder' | 'tertiary' | 'link';
    className?: string;
    size?: Size | 'tiny';
    onClick?: () => void;
    variant?: Variant;
    fullWidth?: boolean;
    disabled?: boolean;
    relativePath?: boolean;
};

export const Button = ({
    children,
    className = '',
    target = '_self',
    type = 'primary',
    size = 'medium',
    variant = 'standard',
    fullWidth = false,
    disabled = false,
    relativePath = false,
    ...props
}: PropsWithChildren<ButtonProps>) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const classNames = clsx(
        'r-rounded-full r-gap-3 r-font-bold r-inline-flex r-items-center r-text-sm r-min-w-[90px] r-justify-center r-text-center',
        {
            'r-self-start': !fullWidth,
            'r-w-full': fullWidth,
            'r-min-w-[140px]': size !== 'tiny' && type !== 'link',
            'r-py-2 r-px-4': size === 'tiny',
            'r-text-base r-py-2 r-px-3': size === 'small' && type !== 'link',
            'r-text-lg r-p-4': size === 'medium' && type !== 'link',
            'r-text-xl r-p-6': size === 'large' && type !== 'link',
            'r-bg-primary-200 r-text-white hover:r-bg-primary-300 r-transition-all': type === 'primary',
            'r-text-primary-300 r-border r-border-primary-300 r-bg-white': type === 'secondary' || type === 'secondaryBorder' || type === 'tertiary',
            'r-text-primary-300 r-px-0 r-underline r-underline-offset-2 r-font-base': type === 'link',
            'r-text-sm': type !== 'link',
            'r-cursor-pointer': !disabled,
            'r-cursor-not-allowed r-opacity-75': disabled,
        },
        className
    );

    const anchor = () => {
        if (typeof props.to !== 'string') {
            return null;
        }

        const [, anchor] = props.to.split('#');
        return anchor;
    };

    const onClickHandler = () => {
        const documentAnchor = anchor();
        if (documentAnchor) {
            document.getElementById(documentAnchor)?.scrollIntoView({
                behavior: 'smooth',
            });

            if (props.to) {
                const [url] = props.to.split('#');
                const urlTo = removeTrailingSlash(url);

                // Only trigger a navigate callback if the url we should navigate to
                // is different to the current page.
                if (urlTo !== pathname) {
                    navigate(props.to);
                }
            }
        }

        if (props.onClick) {
            props.onClick();
        }
    };

    const renderContent = () => (
        <>
            <span>{children}</span>
            <Icon variant={variant} />
        </>
    );

    // This is a special case: If there is an anchor link - handle this as a button
    // and make sure we don't reload this page in the onClickHandler callback.
    // Otherwise the `to` property will trigger a rerender of the current page
    // which has some weird side-effects.
    if (typeof props.to === 'string' && !anchor()) {
        return (
            <Link
                to={props.to}
                target={target}
                data-component="button"
                className={classNames}
                onClick={onClickHandler}
                relative={relativePath ? 'path' : 'route'}
            >
                {renderContent()}
            </Link>
        );
    }

    return (
        <button data-component="button" className={classNames} onClick={onClickHandler} disabled={disabled}>
            {renderContent()}
        </button>
    );
};
