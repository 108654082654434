import { useContext, useEffect, useRef, useState } from 'react';
import { StepOne } from './step-one';
import { StepTwo, StepTwoProps } from './step-two';
import { StepThree } from './step-three';
import { Image } from './jula-account-verification-types';
import { useGtm } from '@/hooks/use-gtm';
import { BlockBase } from '@/queries/page';
import { AppContext } from '@/contexts/app-context';
import { Loading } from './svg';
import { useJula } from './use-jula';

export type PutUserDataArgs = {
    phone: string;
    email: string;
    consentApproved: boolean;
};

export type JulaAccountVerificationProps = {
    loginButtonLabel: string;
    mobileLoginPageImage: Image;
    desktopLoginPageImage: Image;
    informationPageHeadline: string;
    informationPageSubHeadline: string;
    gdprText: string;
    gdprLink: {
        url: string;
        title: string;
        target?: string;
        rel?: string;
    };
    loginPageInnerBlocks: BlockBase[];
    saveInformationButtonLabel: string;
    submitUserDataErrorMessage: string;
    footnote: string;
    confirmationPageHeadline: string;
    confirmationPageCouponCodeText: string;
    confirmationPageApplyForUpgradeQuestionText: string;
    confirmationPageBulletPointsHeading: string;
    confirmationPageBulletPoints: string[];
    confirmationPageApplyButtonLink: {
        url: string;
        title: string;
        target?: string;
        rel?: string;
    };
};

export const JulaAccountVerification = (props: JulaAccountVerificationProps) => {
    const context = useContext(AppContext);
    const { sendPageInteraction } = useGtm();
    const containerRef = useRef<HTMLDivElement>(null);

    const [step, setStep] = useState(1);
    const [isLoading, setIsLoading] = useState(context?.user?.isAuthenticated);
    const { userData, setUserData, applicationError, setApplicationError, put, get, sendEmail } = useJula();

    useEffect(() => {
        if (containerRef?.current) {
            window.scrollTo({
                behavior: 'smooth',
                top: containerRef.current.getBoundingClientRect().top - document.body.getBoundingClientRect().top - 250,
            });
        }
    }, [step]);

    useEffect(() => {
        const isAuthenticated = context?.user?.isAuthenticated ?? false;
        const progress = !isAuthenticated ? 1 : 2;
        setStep(progress);

        if (isAuthenticated) {
            fetchUserData();
            return;
        }

        setIsLoading(false);
    }, [context?.user?.isAuthenticated]);

    const fetchUserData = async () => {
        await get();
        setIsLoading(false);
    };

    const handleLogin = () => {
        sendPageInteraction({ action: 'jula-verifiering', label: 'login' });
        window.location.href = `/login?customRedirectUrl=${context.url}${location.pathname}&scope=julaScope`;
    };

    const onSubmit = async (consetApproved: boolean) => {
        sendPageInteraction({ action: 'jula-verifiering', label: 'submit' });

        const { success: userSuccess } = await put(consetApproved);
        if (!userSuccess) {
            setApplicationError(props.submitUserDataErrorMessage);
            return;
        }

        const { success, message } = await sendEmail(userData.email);
        if (!success) {
            setApplicationError(message);
            return;
        }

        setStep(3);
    };

    const stepTwoProps: StepTwoProps = {
        onSubmit,
        userData,
        setUserData,
        applicationError,
        footnote: props.footnote,
        informationPageHeadline: props.informationPageHeadline,
        informationPageSubHeadline: props.informationPageSubHeadline,
        saveInformationButtonLabel: props.saveInformationButtonLabel,
    };

    const stepThreeProps = {
        confirmationPageHeadline: props.confirmationPageHeadline,
        confirmationPageCouponCodeText: props.confirmationPageCouponCodeText,
        confirmationPageApplyForUpgradeQuestionText: props.confirmationPageApplyForUpgradeQuestionText,
        confirmationPageBulletPointsHeading: props.confirmationPageBulletPointsHeading,
        confirmationPageBulletPoints: props.confirmationPageBulletPoints,
        confirmationPageApplyButtonLink: props.confirmationPageApplyButtonLink,
    };

    const renderStepContent = () => {
        switch (step) {
            case 1:
                return (
                    <StepOne
                        handleLogin={handleLogin}
                        gdprText={props.gdprText}
                        gdprLink={props.gdprLink}
                        loginPageInnerBlocks={props.loginPageInnerBlocks}
                        mobileLoginPageImage={props.mobileLoginPageImage}
                        desktopLoginPageImage={props.desktopLoginPageImage}
                        loginButtonLabel={props.loginButtonLabel ? props.loginButtonLabel : 'Login'}
                    />
                );
            case 2:
                return <StepTwo {...stepTwoProps} />;
            case 3:
                return <StepThree {...stepThreeProps} />;
            default:
                return null;
        }
    };

    if (isLoading) {
        return (
            <div className="r-flex r-min-h-72 r-flex-col r-items-center r-justify-center" role="status">
                <Loading />
            </div>
        );
    }

    return (
        <div ref={containerRef} data-component="jula-verification-flow">
            {renderStepContent()}
        </div>
    );
};
