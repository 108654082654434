import { ApiFetchType } from '@/server/api-fetch';

export type GeneralSettingsApiResponse = {
    humany_settings: {
        humany_base_url: string | null;
        humany_client_id: string | null;
        humany_page_link: string | null;
        humany_page_link_business: string | null;
        humany_ai_chatbot_widget_name: string | null;
        humany_enable_search_results: boolean | null;
        humany_search_api: string | null;
    };
    mfn_single_page_link: string | null;
    mfn_landing_page_link?: string | null;
    consumerloan_version: string;
    external_modules_version: string;
    cookie_read_more_page: string | null;
};

export const GENERAL_SETTINGS_QUERY_KEY = 'general-settings';

export const getGeneralSettings = (context: ApiFetchType) =>
    context.fetch ? context.fetch<GeneralSettingsApiResponse>('api/general/general') : null;
