import { useLogout } from '@/hooks/use-logout';
import { Button } from '../../typography/button/button';
import { TaDa } from './svg';
import { useEffect } from 'react';
import { useGtm } from '@/hooks/use-gtm';

type StepThreeProps = {
    confirmationPageHeadline: string;
    confirmationPageCouponCodeText: string;
    confirmationPageApplyForUpgradeQuestionText: string;
    confirmationPageBulletPointsHeading: string;
    confirmationPageBulletPoints: string[];
    confirmationPageApplyButtonLink: {
        url: string;
        title: string;
        target?: string;
        rel?: string;
    };
};

export const StepThree = (props: StepThreeProps) => {
    const { logout } = useLogout();
    const { sendPageInteraction } = useGtm();

    useEffect(() => {
        setTimeout(() => {
            logout();
        }, 7500);

        return () => {
            logout();
        };
    }, []);

    const bulletPoints =
        props.confirmationPageBulletPoints && props.confirmationPageBulletPoints.filter(p => p).length
            ? props.confirmationPageBulletPoints
            : [
                  'Möjlighet till delbetalning, tex räntefritt i upp till 12 månader',
                  'Betala först när fakturan kommer, månaden efter ditt köp',
                  'Ingen årsavgift',
              ];

    return (
        <div className="sm:r-flex sm:r-justify-center">
            <div className="r-space-y-6 sm:r-max-w-[600px] sm:r-text-center">
                <div className="r-flex r-items-center r-justify-center">
                    <TaDa />
                </div>
                <div>
                    <h2 className="r-mb-4 r-text-xl r-font-bold">{props.confirmationPageHeadline}</h2>
                    <p>{props.confirmationPageCouponCodeText}</p>
                </div>
                <h2 className="r-text-xl r-font-bold">{props.confirmationPageApplyForUpgradeQuestionText}</h2>
                <div className="r-mb-12 r-rounded-[16px] r-border-2 r-border-primary-200 r-p-4 r-text-left">
                    <h3 className="r-mb-4 r-text-base r-font-bold">{props.confirmationPageBulletPointsHeading}</h3>
                    <ul className="r-mb-4 r-list-disc r-pl-6">
                        {bulletPoints.map((item, index) => (
                            <li key={index}>
                                <p>{item}</p>
                            </li>
                        ))}
                    </ul>
                    <Button
                        target="_blank"
                        fullWidth={true}
                        to={props.confirmationPageApplyButtonLink.url}
                        onClick={() => sendPageInteraction({ action: 'jula-verifiering', label: 'apply' })}
                    >
                        {props.confirmationPageApplyButtonLink.title}
                    </Button>
                </div>
            </div>
        </div>
    );
};
