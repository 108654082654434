import { Link } from 'react-router-dom';
import { MenuItem } from './menu-item';
import { MenuItemType } from '@/queries/header';
import { SubMenuItem } from './sub-menu-item';

type MenuItems = {
    items: MenuItemType[];
    setShowMenu: (show: boolean) => void;
};

export default function MenuItems(props: MenuItems) {
    return (
        <div className="r-space-y-7">
            {props.items.map((item: MenuItemType) => (
                <MenuItem key={item.ID} title={item.title} to={item.url} setShowMenu={props.setShowMenu}>
                    {item?.children?.map((child: MenuItemType) => (
                        <SubMenuItem key={child.ID} title={child.title} to={child.url} setShowMenu={props.setShowMenu}>
                            {child?.children?.map((subChild: MenuItemType) => (
                                <div key={subChild.ID} className="r-text-lg r-font-light r-text-gray-500">
                                    <Link to={subChild.url} onClick={() => props.setShowMenu(false)}>
                                        {subChild.title}
                                    </Link>
                                </div>
                            ))}
                        </SubMenuItem>
                    ))}
                </MenuItem>
            ))}
        </div>
    );
}
