import { useContext, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { ProgressBar } from './progress-bar';
import { StepOne } from './step-one';
import { StepTwo } from './step-two';
import { StepThree } from './step-three';
import { StepFour } from './step-four';
import { StepFive } from './step-five';
import { AppContext } from '@/contexts/app-context';
import { BankOpenSavingsFormProps, FieldsType } from './types';
import { useKycSurvey } from '@/hooks/use-kyc-survey';
import { useGtm } from '@/hooks/use-gtm';

type KycValues = Record<string, Record<number, string[]>>;

const initialValues: FieldsType = {
    name: '',
    streetAddress: '',
    zipCode: '',
    city: '',
    phoneDay: '',
    phoneMobile: '',
    contactEmail: '',
    clearingNumber: '',
    accountNumber: '',
    bankName: '',
    fixedValue: '',
    directDebitDay: '',
    directDebitAmount: '',
    directDebitBankName: '',
    directDebitClearingNumber: '',
    directDebitAccountNumber: '',
    acceptDepositGuarantee: false,
    acceptCommonTerms: false,
};

export const specificPropsTruthy = (obj: FieldsType, requiredProps: Array<keyof FieldsType>) => requiredProps.every(key => Boolean(obj[key]));

export const BankOpenSavingsForm = ({ labels, depositGuaranteePopupText }: BankOpenSavingsFormProps) => {
    const context = useContext(AppContext);
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const { sendPageInteraction } = useGtm();
    const containerRef = useRef<HTMLDivElement>(null);
    const { data: kycSurveyData, isLoading } = useKycSurvey();

    const [step, setStep] = useState(1);
    const [values, setValues] = useState<FieldsType>(initialValues);
    const [kycValues, setKycValues] = useState<Record<string, Record<string, string[]>>>({});

    useEffect(() => {
        const isAuthenticated = context?.user?.isAuthenticated ?? false;
        const progress = !isAuthenticated ? 1 : Number(sessionStorage.getItem('progress') ?? 1);
        setStep(progress);

        if (isAuthenticated) {
            const kycValues = sessionStorage?.getItem('kycValues');
            if (kycValues) {
                setKycValues(JSON.parse(sessionStorage.getItem('kycValues') || '{}'));
            }

            const formValues = sessionStorage?.getItem('formValues');
            if (formValues) {
                setValues(JSON.parse(sessionStorage.getItem('formValues') || '{}'));
            }
        }
    }, [context?.user?.isAuthenticated]);

    useEffect(() => {
        if (!kycSurveyData || isLoading) {
            return;
        }
        const { applicant } = kycSurveyData.result;
        const [firstAddress] = applicant.addresses;
        setValues({
            ...values,
            name: applicant.name,
            streetAddress: firstAddress?.street || '',
            zipCode: firstAddress?.zipCode || '',
            city: firstAddress?.city || '',
            phoneMobile: values.phoneMobile,
        });
    }, [kycSurveyData]);

    useEffect(() => {
        // Save to session storage every time values change
        sessionStorage.setItem('formValues', JSON.stringify(values));
    }, [values]);

    useEffect(() => {
        sessionStorage.setItem('progress', JSON.stringify(step));
    }, [step]);

    useEffect(() => {
        if (searchParams.get('response') === 'success') {
            sessionStorage.removeItem('formValues');
            sessionStorage.removeItem('progress');
            sessionStorage.removeItem('kycValues');
            setValues(initialValues);
        }
    }, [values, step]);

    const handleChange = (value: string | boolean, name: keyof FieldsType) => setValues(prev => ({ ...prev, [name]: value }));

    const handleKycChange = (groupId: string, questionId: number, optionText: string, multiple = false, isChecked?: boolean) => {
        setKycValues((prevKycValues: KycValues) => {
            const prevGroup = prevKycValues[groupId] || {};
            const prevAnswers = prevGroup[questionId] || [];

            let updatedAnswers: string[] = [];

            if (multiple) {
                if (isChecked === true) {
                    updatedAnswers = [...prevAnswers, optionText];
                } else if (isChecked === false) {
                    updatedAnswers = prevAnswers.filter(prevValue => prevValue !== optionText);
                }
            } else {
                updatedAnswers = [optionText];
            }

            const updatedGroup = { ...prevGroup, [questionId]: [...new Set(updatedAnswers)] };
            const updatedKycValues = { ...prevKycValues, [groupId]: updatedGroup };

            if (typeof window !== 'undefined') {
                sessionStorage.setItem('kycValues', JSON.stringify(updatedKycValues));
            }

            return updatedKycValues;
        });
    };

    const handleLogin = () => {
        window.location.href = `/login?customRedirectUrl=${context.url}${location.pathname}&scope=depositsApplicationScope`;
    };

    const renderStepContent = () => {
        const props = {
            labels,
            handleChange,
            values,
            setStep: (value: number) => {
                setStep(value);
                if (containerRef?.current) {
                    window.scrollTo({
                        behavior: 'smooth',
                        top: containerRef.current.getBoundingClientRect().top - document.body.getBoundingClientRect().top - 250,
                    });
                }
            },
            user: context.user,
            kycResponse: kycSurveyData,
            handleKycChange,
            kycValues,
            depositGuaranteePopupText,
        };

        switch (step) {
            case 1:
                return <StepOne {...props} login={handleLogin} />;
            case 2:
                return <StepTwo {...props} />;
            case 3:
                return <StepThree {...props} />;
            case 4:
                return <StepFour {...props} />;
            default:
                return null;
        }
    };

    useEffect(() => {
        if (step === 1 && context?.user?.isAuthenticated) {
            sendPageInteraction({ action: 'Open savings account', label: `Step ${step}` });
        } else if (step < 5) {
            sendPageInteraction({ action: 'Open savings account', label: `Step ${step}` });
        }
    }, [step]);

    return (
        <div data-component="bank-open-savings-form" className="r-mt-10 r-flex r-w-full r-max-w-[806px] r-flex-col r-px-5 r-py-10 r-shadow-md">
            {searchParams.get('response') === 'success' ? (
                <StepFive labels={labels} />
            ) : (
                <div ref={containerRef} className="r-mb-4 r-space-y-8">
                    <p className="r-text-2xl r-font-bold r-text-primary-500">{labels.formTitle}</p>
                    <ProgressBar totalSteps={4} step={step} label={labels.toSigning} />
                    {renderStepContent()}
                </div>
            )}
        </div>
    );
};
