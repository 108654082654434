import { useEffect } from 'react';
import { Labels } from './types';
import { Button } from '../../typography/button/button';
import { useLocalization } from '@/hooks/use-localization';
import { useLogout } from '@/hooks/use-logout';
import { useGtm } from '@/hooks/use-gtm';

type Props = {
    labels: Labels;
};

export const StepFive = ({ labels }: Props) => {
    const { localize } = useLocalization();
    const { logout } = useLogout();
    const { sendPageInteraction } = useGtm();
    let timeout: NodeJS.Timeout | null = null;

    useEffect(() => {
        timeout = setTimeout(() => logout(), 3000);

        sendPageInteraction({ action: 'Open savings account', label: 'Done' });

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, []);

    return (
        <div className="r-space-y-4">
            <p className="r-text-xl r-font-bold">{labels.applicationSentTitle}</p>
            <p>{labels.applicationSentDescription}</p>
            <Button relativePath to="..">
                {localize('close')}
            </Button>
        </div>
    );
};
