import { useEffect, useState } from 'react';
import { Modal } from '../modal/modal';
import { Button } from '../typography/button/button';
import { useCookies } from '@/hooks/use-cookies';
import imgUrl from '@/assets/logos/new/resurs-logo-with-symbol-2022.svg';
import { Toggle } from '../forms/components/toggle';
import { setDataLayer } from '@/hooks/use-gtm';
import clsx from 'clsx';

export type CookieType = {
    title: string;
    name: string;
    description: string;
    can_toggle: boolean;
    cookie_requires: string;
};

export const CookieConsent = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [showEditDescription, setShowEditDescription] = useState(false);
    const {
        cookiesData: { data },
        COOKIE_PAGE,
    } = useCookies();
    const [enabledCookies, setEnabledCookies] = useState<string[]>([]);

    useEffect(() => {
        // cookies.data will only exist if no cookies have been set, otherwise no request will even be made
        if (data) {
            setIsOpen(true);
        }

        if (!enabledCookies?.length) {
            setEnabledCookies(data?.types.map((type: CookieType) => type.name) || []);
        }
    }, [data]);

    useEffect(() => {
        // only set those cookies that are always enabled
        if (data && showEditDescription) {
            setEnabledCookies(data.types.filter((type: CookieType) => type.can_toggle === false).map((type: CookieType) => type.name));
        } else if (data && !showEditDescription) {
            setEnabledCookies(data.types.map((type: CookieType) => type.name));
        }
    }, [showEditDescription]);

    if (!data) {
        return null;
    }

    const overviewDescription = data?.overview_description || '';
    const updatedOverviewDescription = overviewDescription.replace(/<h1/g, '<h1 class="r-text-3xl r-font-bold"');

    const editDescription = data?.edit_description || '';
    const updatedEditDescription = editDescription.replace(/<h1/g, '<h1 class="r-text-3xl r-font-bold"');

    const setCookies = (cookieString: string) => {
        const cookieValues: Record<string, string> = {
            // eslint-disable-next-line camelcase
            cookie_consent: encodeURIComponent(cookieString),
            path: '/',
        };

        if (window?.location?.protocol === 'https:') {
            cookieValues.secure = 'true';
            cookieValues.domain = `.${window?.location?.host || ''}`;
        }

        const cookie = Object.entries(cookieValues)
            .map(([key, value]) => `${key}=${value}`)
            .join('; ');

        document.cookie = cookie;
    };

    const acceptCookies = () => {
        if (typeof document === 'undefined' || typeof window === 'undefined') {
            return;
        }

        const intersection = data?.types.map((type: CookieType) => type.name).filter(x => !enabledCookies.includes(x));
        const cookieString = intersection.length
            ? enabledCookies.map(cookie => `${cookie}:1`).join(',') + ',' + intersection.map(cookie => `${cookie}:0`).join(',')
            : enabledCookies.map(cookie => `${cookie}:1`).join(',');

        setCookies(cookieString);
        setIsOpen(false);
        setDataLayer({ event: 'cookie_consent_set' });

        if (window.location.pathname === COOKIE_PAGE) {
            window.location.href = `${window.location.origin}${window.location.pathname}?cookies_set=OK`;
        } else {
            window.location.reload();
        }
    };

    const acceptOnlyNecessaryCookies = () => {
        if (typeof document === 'undefined' || typeof window === 'undefined') {
            return;
        }

        const cookieTypes = data?.types;
        const necessaryCookieTypeName = cookieTypes.filter((type: CookieType) => type.can_toggle === false).map((type: CookieType) => type.name);
        const cookieString = necessaryCookieTypeName.join(':1,') + ':1';

        setCookies(cookieString);
        setIsOpen(false);
        setDataLayer({ event: 'cookie_consent_set' });

        if (window.location.pathname === COOKIE_PAGE) {
            window.location.href = `${window.location.origin}${window.location.pathname}?cookies_set=OK`;
        } else {
            window.location.reload();
        }
    };

    const showOnlyNecessaryCookiesButton = () => !!data?.labels.only_necessary_cookies_button;

    return (
        <Modal
            open={isOpen}
            onClose={() => setIsOpen(false)}
            disableBackdropClick
            paddingDisabled
            actions={
                <div
                    className={clsx('r-my-2 r-flex r-w-full r-gap-2 r-py-2 max-md:r-mb-16 max-md:r-mt-2', {
                        'r-flex-col r-space-y-2': showOnlyNecessaryCookiesButton(),
                    })}
                >
                    <Button onClick={acceptCookies} fullWidth className="r-w-full">
                        {!showEditDescription ? data?.labels.accept_button : data?.labels.save_button}
                    </Button>
                    {showOnlyNecessaryCookiesButton() ? (
                        <Button onClick={acceptOnlyNecessaryCookies} fullWidth className="r-w-full">
                            {data?.labels.only_necessary_cookies_button}
                        </Button>
                    ) : null}
                    <Button type="secondaryBorder" onClick={() => setShowEditDescription(v => !v)} fullWidth className="r-w-full">
                        {!showEditDescription ? data?.labels.edit_button : data?.labels.cancel_button}
                    </Button>
                </div>
            }
        >
            <div
                className={`styled-link scrollbar-hide r-space-y-6 r-overflow-scroll r-px-6 sm:r-px-12 md:r-max-h-[800px] md:r-w-[800px] ${
                    !showEditDescription ? 'r-max-w-[600px] r-pb-2 sm:r-px-7' : ''
                }`}
            >
                <div
                    className={clsx('r-flex r-flex-col r-items-center r-space-y-6 r-border-b-2 r-pb-4 r-pt-8 sm:r-pb-12', {
                        '[@media(max-height:667px)]:r-pt-2': !showEditDescription,
                    })}
                >
                    {!showEditDescription ? (
                        <svg
                            width="80"
                            height="80"
                            viewBox="0 0 80 80"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="[@media(max-height:667px)]:r-hidden"
                        >
                            <path
                                d="M40.2341 4.31293C40.1091 3.46918 39.4685 2.78168 38.6248 2.65668C35.1716 2.1723 31.656 2.7973 28.5623 4.43793L16.9998 10.6098C13.9216 12.2504 11.4373 14.8285 9.90601 17.9692L4.17163 29.7817C2.64038 32.9223 2.14038 36.4848 2.74976 39.9223L5.01538 52.8598C5.62476 56.3129 7.29663 59.4692 9.81226 61.9067L19.2341 71.0317C21.7498 73.4692 24.9529 75.0317 28.406 75.5161L41.3748 77.3442C44.8279 77.8286 48.3435 77.2036 51.4373 75.5629L62.9998 69.3911C66.0779 67.7504 68.5623 65.1723 70.0935 62.0161L75.8279 50.2192C77.3591 47.0786 77.8591 43.516 77.2498 40.0785C77.1091 39.2504 76.4216 38.6254 75.5935 38.5004C67.5466 37.2192 61.0935 31.1411 59.2654 23.2817C58.9841 22.0942 58.0154 21.1879 56.8123 21.0004C48.281 19.641 41.5466 12.8754 40.2185 4.31293H40.2341ZM32.4998 32.5004C29.7341 32.5004 27.4998 30.2661 27.4998 27.5004C27.4998 24.7348 29.7341 22.5004 32.4998 22.5004C35.2654 22.5004 37.4998 24.7348 37.4998 27.5004C37.4998 30.2661 35.2654 32.5004 32.4998 32.5004ZM32.4998 52.5004C32.4998 55.2661 30.2654 57.5004 27.4998 57.5004C24.7341 57.5004 22.4998 55.2661 22.4998 52.5004C22.4998 49.7348 24.7341 47.5004 27.4998 47.5004C30.2654 47.5004 32.4998 49.7348 32.4998 52.5004ZM57.4998 52.5004C54.7341 52.5004 52.4998 50.2661 52.4998 47.5004C52.4998 44.7348 54.7341 42.5004 57.4998 42.5004C60.2654 42.5004 62.4998 44.7348 62.4998 47.5004C62.4998 50.2661 60.2654 52.5004 57.4998 52.5004Z"
                                fill="#26938F"
                            />
                        </svg>
                    ) : (
                        <img src={imgUrl} width={150} height={23} alt="Resurs Bank" className="r-py-4" />
                    )}
                    <div
                        className="r-space-y-6 [&>*:first-child]:r-text-center"
                        dangerouslySetInnerHTML={{ __html: !showEditDescription ? updatedOverviewDescription : updatedEditDescription }}
                    />
                </div>
                {showEditDescription && (
                    <div className="r-space-y-6 r-bg-white r-pb-4 sm:r-pb-4">
                        {data?.types.map((type: CookieType) => (
                            <div key={type.name} data-cookie={type.name} className="r-flex r-space-x-4 r-border-b-2 r-pb-4 last:r-border-b-0">
                                <div className="r-flex r-flex-col r-justify-between">
                                    <h2 className="r-py-2 r-text-lg r-font-bold">
                                        {type.title} {!type.can_toggle && `(${data?.mandatory_text})`}
                                    </h2>
                                    <div className="r-text-sm" dangerouslySetInnerHTML={{ __html: type.description }} />
                                </div>
                                <Toggle type={type} enabledTypes={enabledCookies} setEnabledTypes={setEnabledCookies} />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </Modal>
    );
};
