import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext } from '@/contexts/app-context';
import { FieldsType } from '@/isomorphic/components/wp-blocks/bank-open-savings-form/types';

export function useBankOpenSavingsForm() {
    const { url } = useContext(AppContext);
    const { pathname } = useLocation();

    const sanitizeNumber = (value: string) => value?.replace(/[^\d]+/g, '');

    const submit = async (values: FieldsType, kycValues: Record<string, Record<string, string[]>>) => {
        const body = {
            depositGuaranteeAccepted: values.acceptDepositGuarantee,
            termsConditionsAccepted: values.acceptCommonTerms,
            email: values.contactEmail,
            address: {
                street: values.streetAddress,
                zipCode: values.zipCode,
                city: values.city,
            },
            phoneNumbers: {
                homePhoneNumber: values.phoneDay,
                mobilePhoneNumber: values.phoneMobile,
            },
            kycAnswers: kycValues,
            disbursementAccount: {
                clearingNumber: sanitizeNumber(values.clearingNumber),
                accountNumber: sanitizeNumber(values.accountNumber),
            },
            ...(values['fixedValue'] && {
                autogiroAccount: {
                    clearingNumber: sanitizeNumber(values.directDebitClearingNumber),
                    accountNumber: sanitizeNumber(values.directDebitAccountNumber),
                    withdrawalAmount: { amount: values.directDebitAmount, currency: 'SEK' },
                    withdrawalDate: values.directDebitDay,
                    accountHolderIdentity: values.directDebitBankName,
                },
            }),
            onCancelledUri: `${url}${pathname}?response=cancelled`,
            onSuccessUri: `${url}${pathname}?response=success`,
        };

        const response = await fetch('/api/bank/savings-account-form/apply', {
            headers: {
                'content-type': 'application/json',
                credentials: 'include',
            },
            method: 'POST',
            body: JSON.stringify(body),
        });

        const data = await response.json();

        if (data.signUrl) {
            window.location.href = data.signUrl;
        }

        return data;
    };

    return { submit };
}
