export type KYCResponse = {
    result: Result;
};

type Result = {
    name: string;
    phoneNumbers: string[];
    addresses: Address[];
    kycSurvey: KycSurvey;
    termsConditions: boolean;
    _links: Links;
    applicant: Applicant;
    documents: Document;
};

type Address = {
    street: string;
    zipCode: string;
    city: string;
};

export type KycSurvey = {
    groups: KycGroup[];
};

export type KycGroup = {
    id: string;
    title: string;
    type: string;
    questions: Question[];
};

export type Question = {
    id: number;
    text: string;
    type: string;
    options?: Option[];
    displayCondition?: DisplayCondition;
    info?: Info;
};

export type Option = {
    id: number;
    text: string;
};

type DisplayCondition = {
    questionId: number;
    optionId: number;
};

type Info = {
    prompt: string;
    text: string;
};

type Links = {
    apply_savings_account: ApplySavingsAccount;
    savings_account_information: SavingsAccountInformation;
    common_terms_savings_account: CommonTermsSavingsAccount;
    common_terms_autogiro: CommonTermsAutogiro;
    deposit_guarantee: DepositGuarantee;
};

type ApplySavingsAccount = {
    href: string;
    access: string;
};

type SavingsAccountInformation = {
    href: string;
    access: string;
};

type CommonTermsSavingsAccount = {
    href: string;
    access: string;
};

type CommonTermsAutogiro = {
    href: string;
    access: string;
};

type DepositGuarantee = {
    href: string;
    access: string;
};

type Applicant = {
    name: string;
    email: string;
    mobilePhoneNumber: string;
    phoneNumbers: string[];
    addresses: Address2[];
    termsConditions: boolean;
};

type Address2 = {
    street: string;
    zipCode: string;
    city: string;
};

type Document = {
    savingsAccountInformation: string;
    depositGuarantee: string;
    commonTermsAutogiro: string;
    commonTermsSavingsAccount: string;
};

export const KYC_SURVEY_QUERY_KEY = 'KYC_SURVEY_QUERY_KEY';

export const getKycSurvey = async () => {
    try {
        const apiResponse = await fetch('/api/bank/savings-account-form/kyc-survey?account_type=SAVINGS', {
            headers: {
                'content-type': 'application/json',
                credentials: 'include',
            },
            method: 'GET',
        });
        const response = (await apiResponse.json()) as KYCResponse;
        if (!apiResponse || apiResponse.status !== 200) {
            throw new Error('getkycSurvey' + ' unauthorized');
        }

        return response;
    } catch (e: unknown) {
        console.error(e);
        throw e;
    }
};
