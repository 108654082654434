import { useCallback, useEffect, useState } from 'react';
import { Checkbox } from '../../forms/components/checkbox';
import { Field } from '../../forms/components/field';
import { Button } from '../../typography/button/button';
import { Select } from '../../forms/components/select';
import { FieldsType, Labels } from './types';
import { KYCResponse } from '@/queries/kyc-survey';
import { getBankNameByClearingNumber } from '@/hooks/use-get-clearing-number';
import { validators } from '@/hooks/use-validation';

type Props = {
    labels: Labels;
    handleChange: (value: string | boolean, name: keyof FieldsType) => void;
    values: FieldsType;
    setStep: (step: number) => void;
    kycResponse: KYCResponse | undefined;
};

export const StepTwo = ({ labels, handleChange, values, setStep, kycResponse }: Props) => {
    const [nextDisabled, setNextBtnDisabled] = useState(true);
    const [clearingNumberValidated, setClearingNumberValidated] = useState(false);
    const [accountNumberValidated, setAccountNumberValidated] = useState(false);
    const [directDebitAccountNumberValidated, setDirectDebitAccountNumberValidated] = useState(false);
    const [directDebitClearingNumberValidated, setDirectDebitClearingNumberValidated] = useState(false);
    const [directDebitAmountValidated, setDirectDebitAmountValidated] = useState(false);

    // Might need a better solution to the bankAccountNumber validator, right now this is
    // the only way to get the validation to work
    const bankAccountValidatorWrapper = useCallback(() => {
        return validators.bankAccountNumber(values.clearingNumber + values.accountNumber);
    }, [values.clearingNumber, values.accountNumber]);

    const autogiroAccountValidatorWrapper = useCallback(() => {
        return validators.bankAccountNumber(values.directDebitClearingNumber + values.directDebitAccountNumber);
    }, [values.directDebitClearingNumber, values.directDebitAccountNumber]);

    useEffect(() => {
        if (
            clearingNumberValidated &&
            accountNumberValidated &&
            (values.fixedValue
                ? directDebitAccountNumberValidated && directDebitClearingNumberValidated && directDebitAmountValidated && values.directDebitDay
                : true)
        ) {
            setNextBtnDisabled(false);
        } else {
            setNextBtnDisabled(true);
        }
    }, [
        accountNumberValidated,
        clearingNumberValidated,
        directDebitAccountNumberValidated,
        directDebitClearingNumberValidated,
        values.fixedValue,
        directDebitAmountValidated,
        values.directDebitDay,
    ]);

    useEffect(() => {
        handleChange(false, 'fixedValue');
    }, []);

    useEffect(() => {
        if (!values.clearingNumber) {
            return;
        }

        const bankName = getBankNameByClearingNumber(values.clearingNumber);
        handleChange(bankName, 'bankName');
    }, [values.clearingNumber]);

    useEffect(() => {
        if (!values.directDebitClearingNumber) {
            return;
        }

        const bankName = getBankNameByClearingNumber(values.directDebitClearingNumber);
        handleChange(bankName, 'directDebitBankName');
    }, [values.directDebitClearingNumber]);

    return (
        <>
            <div className="r-mb-10 r-mt-4 r-space-y-4 r-border-b r-pb-10">
                <p className="r-text-xl r-font-bold r-text-primary-500">2. {labels.formStepTwoTitle}</p>
                <Checkbox
                    value={values.fixedValue}
                    checked={!!values.fixedValue}
                    onChange={v => handleChange(v, 'fixedValue')}
                    label={
                        <>
                            <div className="styled-link r-flex r-flex-col">
                                <p>{labels.directDebitCheckboxText}</p>
                                <a target="_blank" href={kycResponse?.result.documents.commonTermsAutogiro}>
                                    {labels.directDebitTermsLinkText}
                                </a>
                            </div>
                        </>
                    }
                />
                {values.fixedValue && (
                    <div className="r-space-y-10">
                        <p>{labels.directDebitInfoText}</p>
                        <div className="r-space-y-4">
                            <div className="r-flex r-flex-col md:r-flex-row md:r-space-x-4">
                                <div className="r-basis-1/2 max-md:r-pb-4">
                                    <Field
                                        isValidated={setDirectDebitClearingNumberValidated}
                                        validation={validators.bankAccountClearingNumber}
                                        value={values.directDebitClearingNumber}
                                        onChange={v => handleChange(v, 'directDebitClearingNumber')}
                                        label={`${labels.directDebitClearing} *`}
                                    />
                                </div>
                                <Field
                                    isValidated={setDirectDebitAccountNumberValidated}
                                    validation={autogiroAccountValidatorWrapper}
                                    value={values.directDebitAccountNumber}
                                    onChange={v => handleChange(v, 'directDebitAccountNumber')}
                                    label={`${labels.directDebitAccount} *`}
                                />
                            </div>
                            <div>
                                <Field
                                    grouped="top"
                                    value={values.directDebitBankName}
                                    disabled
                                    onChange={v => handleChange(v, 'directDebitBankName')}
                                    label={`${labels.directDebitBankName} *`}
                                />
                                <Field
                                    grouped="bottom"
                                    validation={v => typeof parseInt(v) === 'number'}
                                    isValidated={setDirectDebitAmountValidated}
                                    type="number"
                                    value={values.directDebitAmount}
                                    onChange={v => handleChange(v, 'directDebitAmount')}
                                    label={`${labels.directDebitAmount} *`}
                                    infoText={labels.directDebitAmountInfoText}
                                />
                            </div>
                            <Select
                                searchable
                                value={values.directDebitDay}
                                infoText={labels.directDebitDayInfoText}
                                onChange={v => handleChange(v as string, 'directDebitDay')}
                                label={labels.directDebitDay}
                                placeholder={labels.directDebitDayFirstOption}
                                options={Array.from({ length: 28 }, (_, i) => ({ value: `${i + 1}`, label: `${i + 1}` }))}
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className={`r-max-w-[560px] r-space-y-4`}>
                <p className="r-text-xl r-font-bold">{labels.payoutTitle}</p>
                <div className="r-flex r-flex-col md:r-flex-row md:r-space-x-4">
                    <div className="r-basis-1/2 max-md:r-pb-4">
                        <Field
                            validation={validators.bankAccountClearingNumber}
                            isValidated={setClearingNumberValidated}
                            value={values.clearingNumber}
                            onChange={v => handleChange(v, 'clearingNumber')}
                            label={`${labels.payoutClearing} *`}
                        />
                    </div>
                    <Field
                        validation={bankAccountValidatorWrapper}
                        isValidated={setAccountNumberValidated}
                        value={values.accountNumber}
                        onChange={v => handleChange(v, 'accountNumber')}
                        label={`${labels.payoutAccount} *`}
                    />
                </div>
                <Field disabled value={values['bankName']} onChange={v => handleChange(v, 'bankName')} label={`${labels.payoutBankName} *`} />
            </div>
            <div className="r-flex r-justify-center r-gap-4 md:r-gap-8">
                <Button type="secondary" fullWidth onClick={() => setStep(1)}>
                    <div className="r-flex r-space-x-1">
                        <img className="r--scale-x-100" src="/assets/icons/arrow-right.svg" height={15} width={15} alt="" />
                        <p>{labels.back}</p>
                    </div>
                </Button>
                <Button disabled={nextDisabled} fullWidth onClick={() => setStep(3)}>
                    <div className="r-flex r-space-x-1">
                        <p>{labels.continue} 3</p>
                        <img src="/assets/icons/arrow-right.svg" height={15} width={15} alt="" />
                    </div>
                </Button>
            </div>
        </>
    );
};
