import { PropsWithChildren } from 'react';
import { Button } from '@/isomorphic/components/typography/button/button';
import { Circle } from '../circle';
import { LabelsType } from '../../labels';

export type Steps = 0 | 1 | 2 | 3 | 4 | 4.5 | 5 | 6;

type Props = {
    title: string;
    step?: Steps;
    getLabel: (key: keyof LabelsType, replacement?: string) => string;
    setStep: (step: Steps) => void;
    isSubmitDisabled?: boolean;
    showButtons?: boolean;
};

export const StepLayout = ({
    children,
    title,
    setStep,
    getLabel,
    step = 1,
    isSubmitDisabled = false,
    showButtons = true,
}: PropsWithChildren<Props>) => {
    const getNextStep = () => {
        if (step === 4) {
            return 4.5;
        }
        if (step === 4.5) {
            return 5;
        }

        return (step + 1) as Steps;
    };

    const getPreviousStep = () => {
        if (step === 4.5) {
            return 4;
        }

        if (step === 5) {
            return 4.5;
        }

        return (step - 1) as Steps;
    };

    return (
        <div className="r-relative r-max-w-xl r-space-y-4 lg:r-mx-auto">
            <div className="r-space-y-3 r-pb-4 r-font-semibold">
                <h2>{getLabel('savingsAccountBusiness')}</h2>
                <h3 className="r-text-3xl">{title}</h3>
            </div>
            <div className="r-hidden r-w-48 lg:r-absolute lg:-r-right-52 lg:r-block">
                <Circle getLabel={getLabel} />
            </div>
            <div>
                {children}
                {showButtons ? (
                    <div className="r-flex r-gap-4 r-pt-8 max-lg:r-flex-col">
                        {step > 1 ? (
                            <Button className="max-lg:r-order-2" fullWidth type="secondary" onClick={() => setStep(getPreviousStep())}>
                                {getLabel('back')}
                            </Button>
                        ) : null}
                        <Button className="max-lg:r-order-1" disabled={isSubmitDisabled} fullWidth onClick={() => setStep(getNextStep())}>
                            {getLabel('next')}
                        </Button>
                    </div>
                ) : null}
            </div>
        </div>
    );
};
