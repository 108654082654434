import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { FieldsType, SupremeCardApplicationLabels, SupremeCardHasAccountResponse, SupremeCardOfferType, SupremeCardUpdatedOfferType } from './types';
import { AppContext } from '@/contexts/app-context';
import { useGtm } from '@/hooks/use-gtm';
import { useLogin } from '@/hooks/use-login';

type Props = {
    labels: SupremeCardApplicationLabels;
    defaultCard: string;
    deferredSigningUrl: string;
};

const PREFIX = 'supreme-card-application';

function getStorage<T>(key: string, defaultValue: T) {
    try {
        let item: string | boolean | number | T = sessionStorage?.getItem(`${PREFIX}-${key}`) ?? defaultValue;
        if (item === 'false') {
            item = false;
        }
        if (item === 'true') {
            item = true;
        }

        return (item as T) ?? defaultValue;
    } catch (error) {
        return defaultValue;
    }
}

function setStorage(key: string, value: string) {
    try {
        return sessionStorage?.setItem(`${PREFIX}-${key}`, value);
    } catch (error) {
        // No biggie
    }
}

const analyticsEvents: string[] = [];

export function useSupremeCardApplication({ labels, defaultCard, deferredSigningUrl }: Props) {
    // Sorry about this, there doesn't seem to be a good way of checking
    // which type this response object is in TypeScript atm...
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const isErrorType = (offer: any): offer is SupremeCardHasAccountResponse => typeof offer.message === 'string';
    const context = useContext(AppContext);
    const location = useLocation();
    const { sendPageInteraction } = useGtm();
    const { getLoginHintQuery } = useLogin();

    const [error, setError] = useState<string | null>(null);
    const [errorTitle, setErrorTitle] = useState<string | null>(null);
    const [offer, setOffer] = useState<SupremeCardOfferType | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [updatedOffer, setUpdatedOffer] = useState<SupremeCardUpdatedOfferType | null>(null);

    const getDefaultSelectedCard = () => {
        const query = queryString.parse(location.search) as Record<string, string>;

        if (query.selectedCard) {
            setStorage('selectedCard', query.selectedCard);
            return query.selectedCard;
        }

        return getStorage('selectedCard', defaultCard);
    };

    function getValuesFromStorage() {
        return {
            selectedCard: getDefaultSelectedCard(),
            personalNumber: getStorage('personalNumber', ''),
            contactEmail: getStorage('contactEmail', ''),
            phoneMobile: getStorage('phoneMobile', ''),
            pepOptions: getStorage('pepOptions', ''),
            acceptTerms: false,
            amount: getStorage('amount', 0),
        };
    }

    const [step, setStep] = useState<1 | 2 | 3>(context.user?.isAuthenticated ? 2 : 1);
    const [fields, setFields] = useState<FieldsType>({
        selectedCard: '',
        personalNumber: '',
        contactEmail: '',
        phoneMobile: '',
        pepOptions: '',
        acceptTerms: false,
        amount: 0,
    });

    useEffect(() => {
        context.setSupremeCardApplication({ selectedCard: getDefaultSelectedCard() });
        setFields(getValuesFromStorage());
    }, []);

    const sendAnalyticsData = (data: Record<string, unknown>, offer?: SupremeCardOfferType) => {
        if (!fields.selectedCard) {
            return;
        }

        const args = {
            category: 'Supreme Card',
            typeOfCard: fields.selectedCard,
            applicationID: offer?.application?.applicationId ?? '',
            ...data,
        };

        const analyticsEvent = JSON.stringify(args);
        const existingEvent = analyticsEvents.find(event => event === analyticsEvent);
        if (existingEvent) {
            return;
        }

        analyticsEvents.push(analyticsEvent);
        sendPageInteraction(args);
    };

    useEffect(() => {
        const query = queryString.parse(location.search) as Record<string, string>;
        if (!context.user) {
            return;
        }

        if (!query.confirmationStatus && !offer) {
            getOffer();
            return;
        }

        if (query.confirmationStatus === 'success') {
            setStep(3);
            registerSignedOffer();
        } else if (query.confirmationStatus === 'postponed') {
            window.location.href = deferredSigningUrl;
        } else {
            setError(labels.errorGenericTitle);
            setStep(1);
        }
    }, [location]);

    useEffect(() => {
        const data: Record<string, unknown> = {
            action: 'Step',
            label: `Step ${step}`,
        };

        if (step === 2 && offer) {
            data.value = offer?.application?.approvedLimit?.amount;

            sendAnalyticsData(
                {
                    action: 'Form Completed',
                    label: offer?.application?.decision,
                    value: data.value,
                },
                offer
            );
        }

        if (step === 2 && !offer) {
            // If we don't wait for the offer we will send the same page_event twice
            // so wait for it here and send only one Page_Interaction event!
            return;
        }

        if (step === 3) {
            data.value = fields.amount;
        }

        sendAnalyticsData(data);
    }, [step, offer, fields]);

    useEffect(() => {
        context.setSupremeCardApplication({ step });
    }, [step]);

    const updateField = (field: Partial<FieldsType>) => {
        setFields({ ...fields, ...field });

        for (const [key, value] of Object.entries(field)) {
            if (typeof value === 'string' || typeof value === 'number') {
                setStorage(key, `${value}`);
            } else {
                setStorage(key, JSON.stringify(value));
            }
        }
    };

    const getLoginUrl = (loginHint?: string) =>
        `/login?customRedirectUrl=${context.url}${location.pathname}&scope=supremeApplicationScope&loginHint=${loginHint}`;

    const onSubmit = async (step = 1) => {
        if (step === 1) {
            sendAnalyticsData({
                action: 'Clicked button',
                label: 'Login and proceed',
            });

            const governmentId = fields.personalNumber.replace(/\D+/g, '');
            const { loginHint } = await getLoginHintQuery(governmentId);
            window.location.href = getLoginUrl(loginHint);
            return;
        }

        if (step === 2) {
            signOffer();
        }
    };

    const registerSignedOffer = async () => {
        await fetch('/api/bank/supreme-card/apply/register', {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
                credentials: 'include',
            },
            method: 'PUT',
        });
    };

    const getOfferType = () => {
        if (context?.user?.isAuthenticated !== true) {
            return '';
        }

        if (context?.user?.hasCardOfferRaise) {
            return 'SUPREME_CARD_LIMIT_RAISE';
        }

        return 'SUPREME_CARD_NEW';
    };

    const getApplyParams = (amount?: number) => {
        if (amount) {
            return {
                amount: {
                    value: amount,
                    currency: context.currency,
                },
                cardType: getStorage('selectedCard', defaultCard),
            };
        }

        return {
            isCustomer: context?.user?.isCustomer ?? false,
            cardType: getStorage('selectedCard', defaultCard),
            email: getStorage('contactEmail', ''),
            mobilePhoneNumber: getStorage('phoneMobile', ''),
            approveConditions: getStorage('acceptTerms', true),
            offerType: getOfferType(),
            isPep: getStorage('pepOptions', 'Ja') === 'Ja',
        };
    };

    const getOffer = async () => {
        setIsLoading(true);
        const data = getApplyParams();

        const response = await fetch('/api/bank/supreme-card/apply', {
            headers: {
                'content-type': 'application/json',
                credentials: 'include',
            },
            method: 'POST',
            body: JSON.stringify(data),
        });

        const offer = (await response.json()) as SupremeCardOfferType | SupremeCardHasAccountResponse;

        setIsLoading(false);

        if (isErrorType(offer)) {
            sendAnalyticsData({
                action: 'Error',
                label: offer?.message || '',
            });
            setError(labels.errorGenericContent);
            return;
        }

        sendAnalyticsData(
            {
                action: 'Clicked button',
                label: 'Login and proceed',
                category: 'Supreme Card',
            },
            offer
        );

        setOffer(offer);

        if (offer.decision === 'MANUAL_INSPECTION') {
            setErrorTitle(labels.manualInspectionTitle);
            setError(labels.manualInspectionContent);
            sendAnalyticsData({
                action: 'Error',
                label: 'Manual Inpection',
            });
            return;
        }

        updateField({ amount: offer.maxLimit.amount });
    };

    const updateOffer = async (amount: number) => {
        const data = getApplyParams(amount);

        const response = await fetch('/api/bank/supreme-card/apply', {
            headers: {
                'content-type': 'application/json',
                accept: 'application/json',
                credentials: 'include',
            },
            method: 'PUT',
            body: JSON.stringify(data),
        });

        if (response.status !== 200) {
            setError(labels.errorGenericTitle);
            context.setSupremeCardApplication({ step: 1 });
            setStep(1);
            return;
        }

        const offer = (await response.json()) as SupremeCardUpdatedOfferType;

        if (offer) {
            sendAnalyticsData({
                action: 'Slider Completed',
                label: 'Supreme Limit Slider',
                value: amount,
            });
            setUpdatedOffer(offer);
        }
    };

    const signOffer = async () => {
        const protocol = context.isProd ? 'https' : 'http';
        const url = `${protocol}://${context.host}`;

        setIsLoading(true);
        sendAnalyticsData({
            action: 'Clicked button',
            label: 'Sign application',
        });

        const successUrl = `${url}${location.pathname}?confirmationStatus=success`;
        const failUrl = `${url}${location.pathname}?confirmationStatus=failed`;
        const postponedUrl = `${url}${location.pathname}?confirmationStatus=postponed`;

        const response = await fetch('/api/bank/supreme-card/apply/sign', {
            headers: {
                'content-type': 'application/json',
                credentials: 'include',
            },
            method: 'POST',
            body: JSON.stringify({
                signatureOkUrl: successUrl,
                signatureCancelledUrl: failUrl,
                signaturePostponedUrl: postponedUrl,
            }),
        });

        const data = await response.json();
        setIsLoading(false);

        if (data.signRedirectUrl) {
            window.location.href = data.signRedirectUrl;
            return;
        }

        if (data?.confirmationStatus === 'failed' || data?.error === true || data?.statusCode !== 200) {
            setError(labels.errorGenericTitle);
        }
    };

    return {
        step,
        setStep,
        fields,
        isLoading,
        updateField,
        updateOffer,
        onSubmit,
        offer,
        updatedOffer,
        error,
        errorTitle,
    };
}
