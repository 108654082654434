import { createContext, useState } from 'react';

export type PrefetchType = (_: { queryKey: unknown; queryFn: () => Promise<unknown> }) => unknown;
export type FetchType = <T>(url: string) => Promise<T>;

export type MyEconomyDrawerType = {
    isOpen: boolean;
    startCourse: string;
};

export type SupremeCardApplicationType = {
    selectedCard: string;
    step: 1 | 2 | 3;
    isError: boolean;
    progressText: {
        title: string;
        content: string;
    };
};

export type AppContextType = {
    version: string;
    role: 'draft' | 'live';
    siteId: 'resursbank_se' | 'resursbank_no' | 'resursbank_dk' | 'resursbank_fi';
    country: 'se' | 'no' | 'dk' | 'fi';
    language: 'sv' | 'no' | 'da' | 'fi';
    locale: 'sv-se' | 'nb-no' | 'da-dk' | 'fi-fi';
    timezone: string;
    currency: 'SEK' | 'NOK' | 'DKK' | 'EUR';
    host: string;
    url: string;
    isProd: boolean;
    deviceType: 'mobile' | 'desktop';
    bankApiKey: string;
    signicatClientId: string;
    reCaptchaSiteKey: string;
    userAgent: string;
    user?: {
        isAuthenticated: boolean;
        isCustomer: boolean;
        hasCardOfferNew: boolean;
        hasCardOfferRaise: boolean;
        accessToken?: string;
        refreshToken?: string;
        tokenExpires?: number;
    };
    prefetch: PrefetchType;
    fetch?: FetchType;
    myEconomyDrawer: MyEconomyDrawerType;
    setMyEconomyDrawer: (args: Partial<MyEconomyDrawerType>) => void;
    supremeCardApplication: SupremeCardApplicationType;
    setSupremeCardApplication: (args: Partial<SupremeCardApplicationType>) => void;
};

const AppContext = createContext<AppContextType>({
    version: 'dev',
    role: 'draft',
    siteId: 'resursbank_se',
    country: 'se',
    language: 'sv',
    locale: 'sv-se',
    timezone: 'Europe/Stockholm',
    currency: 'SEK',
    host: 'resursbank.se',
    url: 'https://www.resursbank.se',
    isProd: true,
    deviceType: 'mobile',
    bankApiKey: '',
    signicatClientId: '',
    reCaptchaSiteKey: '',
    userAgent: '',
    user: {
        isAuthenticated: false,
        isCustomer: false,
        hasCardOfferNew: false,
        hasCardOfferRaise: false,
    },
    prefetch: _ => _,
    myEconomyDrawer: {
        isOpen: false,
        startCourse: 'start',
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setMyEconomyDrawer: (_args: Partial<MyEconomyDrawerType>) => {
        return;
    },
    supremeCardApplication: {
        selectedCard: 'GOLD',
        step: 1,
        isError: false,
        progressText: {
            title: '',
            content: '',
        },
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setSupremeCardApplication: (_args: Partial<SupremeCardApplicationType>) => {
        return;
    },
});

const AppContextProvider = ({ children, prefetch = _ => _, ...rest }: React.PropsWithChildren<AppContextType>) => {
    const [myEconomyDrawer, setMyEconomyDrawer] = useState<MyEconomyDrawerType>({
        isOpen: false,
        startCourse: 'start',
    });

    const [supremeCardState, setSupremeCardState] = useState<SupremeCardApplicationType>({
        selectedCard: 'GOLD',
        step: 1,
        isError: false,
        progressText: {
            title: '',
            content: '',
        },
    });

    return (
        <AppContext.Provider
            value={{
                ...rest,
                prefetch,
                myEconomyDrawer,
                supremeCardApplication: supremeCardState,
                setMyEconomyDrawer: args => {
                    setMyEconomyDrawer({ ...myEconomyDrawer, ...args });
                },
                setSupremeCardApplication: args => {
                    setSupremeCardState(prev => ({ ...prev, ...args }));
                },
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export { AppContextProvider, AppContext };
