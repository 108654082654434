import { Link } from 'react-router-dom';
import { clsx } from 'clsx';
import { useGlobalQueries } from '@/hooks/use-global-queries';
import { useNavItem } from '@/hooks/use-nav-item';
import { useNavMenus } from '@/hooks/use-nav-menus';

export const TopMenu = () => {
    const { getMarketType } = useGlobalQueries();
    const { getTopMenuItems } = useNavMenus();
    const { isCurrentItemActive } = useNavItem();
    const { marketMenu, mainMenu } = getTopMenuItems();
    const STANDARD_LIST_STYLE = 'r-py-1 r-px-2 r-font-semibold';

    if (getMarketType() === 'cluster') {
        return null;
    }

    return (
        <div data-component="top-menu" className="r-bg-[#aed5d5]">
            <div className="r-container r-flex r-justify-between r-px-8 r-py-2 r-align-middle r-text-sm">
                <ul className="r-flex r-gap-6">
                    {marketMenu.map((item, index) => (
                        <li key={index} className={clsx(STANDARD_LIST_STYLE)}>
                            <Link to={item.url.replace(/\/$/, '')} target={item.target || '_self'}>
                                {item.title}
                            </Link>
                        </li>
                    ))}
                </ul>

                <ul className="-r-mr-2 r-flex r-gap-6">
                    {mainMenu.map((item, index) => (
                        <li key={index} className={clsx(STANDARD_LIST_STYLE, { 'r-text-primary-200': isCurrentItemActive(item) })}>
                            <Link to={item.url.replace(/\/$/, '')} target={item.target || '_self'}>
                                {item.title}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};
