import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useLocalization } from '@/hooks/use-localization';
import { Field } from '../../forms/components/field';
import { FieldGroup } from '../bank-open-savings-form-business/components/field-group';
import { useValidation } from '@/hooks/use-validation';
import { Checkbox } from '../../forms/components/checkbox';
import { Button } from '../../typography/button/button';
import { GoogleRecaptchaInfo } from '../../google-recaptcha-info/google-recaptcha-info';
import { UserData } from './use-jula';

export type StepTwoProps = {
    informationPageHeadline: string;
    informationPageSubHeadline: string;
    saveInformationButtonLabel: string;
    footnote: string;
    onSubmit: (consentChecked: boolean) => void;
    userData: UserData;
    setUserData: Dispatch<SetStateAction<UserData>>;
    applicationError?: string;
};

export const StepTwo = ({
    onSubmit,
    footnote,
    userData,
    setUserData,
    applicationError,
    informationPageHeadline,
    informationPageSubHeadline,
    saveInformationButtonLabel,
}: StepTwoProps) => {
    const { localize } = useLocalization();
    const { getValidatorByInputName } = useValidation();
    const [consent, setConsent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const isSubmitDisabled = useMemo(
        () => isLoading || !getValidatorByInputName('phone')(userData.phone) || !getValidatorByInputName('email')(userData.email),
        [userData, isLoading]
    );

    const submit = () => {
        setIsLoading(true);
        onSubmit(consent);
    };

    return (
        <div className="sm:r-flex sm:r-justify-center">
            <div className="sm:r-max-w-[600px]">
                <h2 className="r-mb-4 r-text-xl r-font-bold">{informationPageHeadline}</h2>
                <form
                    onSubmit={e => {
                        e.preventDefault();
                        submit();
                    }}
                >
                    <FieldGroup>
                        <Field grouped="top" label={localize('first-and-last-name')} value={userData.fullName} disabled={true} onChange={() => {}} />
                        <Field grouped="middle" label={localize('address')} value={`${userData.address}`} disabled={true} onChange={() => {}} />
                        <Field
                            grouped="bottom"
                            label={localize('postal-code')}
                            value={`${userData.postalCode}, ${userData.postalArea}`}
                            disabled={true}
                            onChange={() => {}}
                        />
                    </FieldGroup>
                    <h2 className="r-mb-2 r-mt-6 r-text-xl r-font-bold">{localize('contact-information')}</h2>
                    <p className="r-mb-4 r-text-base">{informationPageSubHeadline}</p>
                    <FieldGroup>
                        <Field
                            grouped="top"
                            label={localize('phone')}
                            value={userData.phone}
                            onChange={(val: string) => setUserData({ ...userData, phone: val })}
                            validation={getValidatorByInputName('phone')}
                        />
                        <Field
                            grouped="bottom"
                            label={localize('email')}
                            value={userData.email}
                            onChange={(val: string) => setUserData({ ...userData, email: val })}
                            validation={getValidatorByInputName('email')}
                        />
                    </FieldGroup>
                    <div className="r-mb-4 r-mt-4">
                        <Checkbox
                            checked={consent}
                            onChange={(checked: boolean) => setConsent(checked)}
                            label={`Jag vill ta del av erbjudanden om alla slags produkter och tjänster från Resurs via elektroniskt post*`}
                            labelAlignment="center"
                        />
                    </div>
                    <Button fullWidth={true} disabled={isSubmitDisabled}>
                        {saveInformationButtonLabel}
                    </Button>
                </form>
                <div className="r-mt-4">
                    <GoogleRecaptchaInfo />
                </div>
                {applicationError ? <div className="r-mt-4 r-bg-danger-400 r-p-4 r-text-white">{applicationError}</div> : null}
                <div className="r-mt-4 r-text-sm">*{footnote}</div>
            </div>
        </div>
    );
};
