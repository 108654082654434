import { useState } from 'react';
import clsx from 'clsx';
import { Button } from '@/isomorphic/components/typography/button/button';
import { Checkbox } from '@/isomorphic/components/forms/components/checkbox';
import { KeyRole, PropsType } from '../../types';
import { formatInterest } from '../circle';
import { Steps } from './step-layout';

export const StepFive = ({ fields, onSubmit, getLabel, setStep }: PropsType & { onSubmit: () => void }) => {
    const [checkboxes, setCheckboxes] = useState({ toc: false, guarantee: false });

    const getRoles = (roles: KeyRole[]) =>
        roles
            .map(role => {
                switch (role) {
                    case KeyRole.SIGNATORY:
                        return getLabel('companyPrincipal');
                    case KeyRole.RIGHT_OF_USE:
                        return getLabel('rightOfUse');
                    case KeyRole.RIGHT_OF_INFORMATION:
                        return getLabel('rightOfInformation');
                }
            })
            .join(', ');

    const getKeyRolesRows = () => fields.keyRoles.map(keyRole => [getRoles(keyRole.roles), `${keyRole.name.first} ${keyRole.name.last}`]);

    const summaryFields = [
        {
            title: getLabel('savingsAccount'),
            step: null,
            rows: [
                [getLabel('account'), getLabel('savingsAccountBusiness')],
                [
                    getLabel('interestLabel'),
                    `<span class="r-font-normal r-italic r-pr-1">${getLabel('currently')}</span> ${formatInterest(getLabel('interestRate'))}`,
                ],
            ],
        },
        {
            title: getLabel('companyDetails'),
            rows: [
                [getLabel('companyName'), fields.company.organizationName],
                [getLabel('orgNumber'), fields.company.governmentId.id],
            ],
        },
        {
            title: getLabel('stepThree'),
            step: 3,
            rows: getKeyRolesRows(),
        },
        {
            title: getLabel('transferAccount'),
            step: 1,
            rows: [[getLabel('transferAccount'), `${fields.payoutAccount.clearingNumber} - ${fields.payoutAccount.accountNumber}`]],
        },
    ];

    return (
        <div className="r-relative r-mx-auto r-max-w-xl r-space-y-4">
            <div className="r-space-y-4 r-pb-4 r-font-semibold">
                <h2>{getLabel('savingsAccountBusiness')}</h2>
                <h3 className="r-text-4xl">{getLabel('summany')}</h3>
            </div>

            <p className="max-md:r-hidden md:r-pb-4">{getLabel('checkDetailsText')}</p>
            <div className="r-space-y-6 r-rounded-xl r-border-2 r-border-primary-200 r-bg-white r-p-4">
                {summaryFields.map((summaryField, index) => (
                    <div key={index}>
                        <h4 className={clsx('r-font-semibold', index === 0 ? 'r-pb-4 r-text-xl' : 'r-pb-2')}>{summaryField.title}</h4>
                        {summaryField.rows.map(([key, value], rowIndex) => (
                            <div key={rowIndex} className="r-flex r-w-full r-justify-between r-gap-4 r-border-b r-border-gray-200 r-py-2">
                                <span>{key}</span>
                                <span className="r-text-right r-font-bold" dangerouslySetInnerHTML={{ __html: value }} />
                            </div>
                        ))}
                        {summaryField.step ? (
                            <Button className="r-mt-4 r-p-2" size="tiny" type="secondary" onClick={() => setStep(summaryField.step as Steps)}>
                                {getLabel('change')}
                            </Button>
                        ) : null}
                    </div>
                ))}
                <div className="r-space-y-4 r-rounded-md r-bg-[#e5f2f2] r-p-4" dangerouslySetInnerHTML={{ __html: getLabel('checkDetailsHelp') }} />
            </div>

            <div className="r-space-y-6 r-pt-4">
                <Checkbox
                    checked={checkboxes.toc}
                    label={getLabel('checkboxToc')}
                    onChange={() => setCheckboxes({ ...checkboxes, toc: !checkboxes.toc })}
                />
                <Checkbox
                    checked={checkboxes.guarantee}
                    label={getLabel('checkboxGuarantee')}
                    onChange={() => setCheckboxes({ ...checkboxes, guarantee: !checkboxes.guarantee })}
                />
            </div>

            <div className="r-flex r-gap-4 r-pt-4 max-md:r-flex-col">
                <Button className="max-md:r-order-2" fullWidth type="secondary" onClick={() => setStep(4)}>
                    {getLabel('back')}
                </Button>
                <Button className="max-md:r-order-1" disabled={!checkboxes.toc || !checkboxes.guarantee} fullWidth onClick={onSubmit}>
                    {getLabel('submitForm')}
                </Button>
            </div>
        </div>
    );
};
