import clsx from 'clsx';
import { useMemo } from 'react';
import { DynamicModuleGenerator } from '../dynamic-module-generator/dynamic-module-generator';
import { CookieConsent } from '../cookie-consent/cookie-consent';
import { useGlobalQueries } from '@/hooks/use-global-queries';
import { getImageUrl } from '@/utils/images';
import { useLocalization } from '@/hooks/use-localization';
import { getTailwindColor } from '@/utils/colors';
import { MetaTags } from './components/meta-tags';
import { ContactMe } from '../contact-me/contact-me';
import { HumanyChatBot } from './components/humany-chatbot';
import { Banner } from '../banner/banner';

export const Page = () => {
    const { localize } = useLocalization();
    const { pageQuery } = useGlobalQueries();

    const style: React.CSSProperties = {};
    if (pageQuery.data?.featured_image?.src) {
        style.backgroundImage = `url('${getImageUrl(pageQuery.data.featured_image.src, 'AUTOx1000')}')`;
    }

    const contactMeProps = useMemo(() => {
        if (!pageQuery.data?.contact_me_form) {
            return null;
        }

        const { contact_me_form: form } = pageQuery.data;

        return {
            formId: form.form_id,
            fields: form.form_fields,
            formTitle: form.form_title,
            formSubTitle: form.form_subtitle,
            buttonText: form.button_text,
            submitButtonText: form.submit_form_text,
            thankYouText: form.thank_you_text,
            gtm: form.form_gtm,
        };
    }, [pageQuery.data?.contact_me_form]);

    return (
        <main data-component="page" className={clsx('r-relative r-min-h-[400px]', getTailwindColor(pageQuery.data?.page_background_color, 'bg'))}>
            {pageQuery.isError || !pageQuery.data ? (
                <div className="r-container r-p-8">
                    <p className="r-bg-danger r-p-4 r-text-white">{localize('error-generic')}</p>
                </div>
            ) : (
                <>
                    <MetaTags />
                    <CookieConsent />
                    {pageQuery.data?.contact_me_form ? (
                        <div style={style} className="r-absolute r-top-0 r-h-[330px] r-w-full r-bg-cover r-bg-center r-bg-no-repeat md:r-h-[450px]" />
                    ) : null}
                    {pageQuery.data.banner_cta_link ? (
                        <Banner title={pageQuery.data.banner_title} text={pageQuery.data.banner_cta_text} link={pageQuery.data.banner_cta_link} />
                    ) : null}
                    {contactMeProps ? <ContactMe {...contactMeProps} /> : null}
                    <div className="r-z-10">
                        <DynamicModuleGenerator blocks={pageQuery.data?.blocks} />
                    </div>
                    <HumanyChatBot />
                </>
            )}
        </main>
    );
};
